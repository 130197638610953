import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// redux
import { connect } from 'react-redux';

// ui 
import { Text } from '@audi/audi-ui-react';

// modules
import Banner from './modules/Banner';
import OrderForm from './modules/OrderForm';
import DualTeaser from './modules/DualTeaser';
import Footer from './modules/Footer';

// plugins
import {WordsInstant} from './plugins/Words';
import { scroller } from 'react-scroll';

class PreOrder extends Component {
	static propTypes = {
	    location: PropTypes.object.isRequired,
	    history: PropTypes.object.isRequired
	}
	constructor(props){
		super(props);
		let arabic = props.arabic;
		let modelName = arabic ? props.modelNameAR : props.modelName;
		this.state = {
			locale:{
				banner:{
					title:{
						ar:'مع الهيبة الكبيرة تأتي محدودية التّوفر.',
						en:'With great prestige comes limited availability.'
					},
					body:{
						ar:`تميز عن الجميع. احجز مكانك على قائمة الانتظار للحصول على نسختك من سيارة ${modelName} قبل نفاذ الكمية المخصّصة لأسواقنا.`,
						en:`Stand out from the crowd. Join the waitlist to reserve your ${modelName} before regional allocation is exhausted.`
					},
					cta1:{
						ar:'انضم إلى قائمة الانتظار',
						en:'Join the waitlist'
					},
					cta2:{
						ar:'ابق على اطّلاع',
						en:'Learn more'
					}
				},
				bar:{
					ar:`انضم إلى قائمة الانتظار للحصول على نسختك من سيارة ${modelName}`,
					en:`Join the ${modelName} waitlist to secure yours.`
				}
			}
		}
	}
	componentDidMount(){

	}
	loadThanks = () => {
		const { history,arabic,regionSlug,modelSlug } = this.props;
		window.scrollTo(0, 0);
		history.push(arabic ? `/${modelSlug}/${regionSlug}/ar/thanks`:`/${modelSlug}/${regionSlug}/thanks`);
	}
	loadCheckout = () => {
		const { history,arabic,regionSlug,modelSlug } = this.props;
		window.scrollTo(0, 0);
		history.push(arabic ? `/${modelSlug}/ar/checkout`:`/${modelSlug}/checkout`);
	}
	scrollForm = () => {
		scroller.scrollTo('bar', {
	      	duration: 1000,
	      	delay: 0,
	      	offset:0,
	      	smooth: 'easeInOut'
	    }) 
	}
	render(){
		const { arabic, mobi, model, purchase, region, baseurl, modelName, modelNameAR, modelSlug, regionSlug } = this.props;
		let locale = this.state.locale,
		learnLink = arabic ? `${baseurl}models/${model}`:`${baseurl}models/e-tron-gt/${model}.html`;
		return (
			<div className="container" dir={arabic ? 'rtl':'ltr'} lang={arabic ? 'ar':'en'}>
				<div className="content">
					<Banner 
						title={locale.banner.title[arabic ? 'ar':'en']} 
						body={locale.banner.body[arabic ? 'ar':'en']} 
						buttons={[{text:locale.banner.cta1[arabic ? 'ar':'en'],click:this.scrollForm,type:'primary'},{text:locale.banner.cta2[arabic ? 'ar':'en'],href:learnLink,type:'secondary'}]}
						model={model}
						region={region}
						/>

					<div className="bar">
						<div className="bar-inner">
							<Text as="h2" variant="order3" weight="bold">
								<WordsInstant text={locale.bar[arabic ? 'ar':'en']} delay={mobi ? 0:1}/>
							</Text>
						</div>
					</div>
					<OrderForm purchase={purchase} region={region} model={model} name={arabic ? modelNameAR : modelName} loadThanks={this.loadThanks} loadCheckout={this.loadCheckout}/>
					<Footer region={region} modelSlug={modelSlug} regionSlug={regionSlug}/>
				</div>
			</div>
		);
	}
}

// connects redux store to component
function mapStateToProps(state){
  	return {
  		mobi:state.mobi,
  		baseurl:state.baseurl
  	}
}

// export with redux connect
export default withRouter(connect(mapStateToProps)(PreOrder));

