import React, { Component,Fragment } from 'react';

// ui
import { Layout,LayoutItem,Text,Button } from '@audi/audi-ui-react';

// plugins
import {Words} from '../plugins/Words';
import Fade from 'react-reveal/Fade';
import { FaFacebookF,FaYoutube,FaTwitter,FaInstagram,FaLinkedin } from 'react-icons/fa';

class Intro extends Component {

	constructor(props){
		super(props);
		this.state = {
			facebook:'https://www.facebook.com/AudiMiddleEast',
			youtube:'https://www.youtube.com/user/AudiMiddleEast',
			twitter:'https://twitter.com/AudiMiddleEast',
			instagram:'https://www.instagram.com/audimiddleeast/',
			linkedin:'https://www.linkedin.com/company/audi-middle-east'
		}
	}
	render(){
		const { text,body,error,buttons,space } = this.props;
		// render
		return ( 
			<div className={`module intro ${space ? 'space':''}`}>
				<Text as="h1" variant="display2" weight="bold" spaceStackEnd="s" className="center">
	  				<Words text={text} delay={0}/>
				</Text>
				{
					error ? 
						<Text as="h1" variant="order4" spaceStackStart="l" spaceStackEnd="l" className="center">
  							<Words text={`Reason: ${error}`} delay={0.5}/>
						</Text>
					:false
				}
				{
					body ? 
						<Text as="p" variant="copy1" spaceStackStart="l" spaceStackEnd="l" className="center">
  							<Words text={body} delay={0.5}/>
						</Text>
					:false
				}
				{
					buttons && buttons.length ?
					<Layout className="button-group">
						{
						buttons.map((button,b)=>{
							return (
								<LayoutItem key={b} spaceInlineStart="xxs" spaceInlineEnd="xxs">
								<Fade 
									bottom 
									delay={1200}
									duration={1000}
									distance={'60%'}>
									{
										button.social ? 
										<Button variant={`${button.type}`} spaceStackStart="s" className={`${button.type} ${button.inverse ? 'inverse':''} social-btn`} onClick={button.click} href={button.href}>
											<span className="social-text">{button.text}</span>
											<div className="social-btns">
												<a href="https://www.facebook.com/sharer/sharer.php?u=https://audi-me.com/rs-e-tron-gt.html?utm_source=shared-from-facebook&quote=I've%20officially%20joined%20the%20Audi%20RS%20e-tron%20GT%20family!%20Who%20wants%20to%20go%20for%20a%20spin?#FutureIsAnAttitude%20#LivingProgress" target="_blank"><FaFacebookF/></a>
												<a href="https://twitter.com/intent/tweet?text=I've%20officially%20joined%20the%20Audi%20RS%20e-tron%20GT%20family!%20Who%20wants%20to%20go%20for%20a%20spin?&url=https://audi-me.com/rs-e-tron-gt.html?utm_source=shared-from-twitter&hashtagsFutureIsAnAttitude,LivingProgress&via=audimiddleeast" target="_blank"><FaTwitter/></a>
											</div>
										</Button>
										:
										<Button variant={`${button.type}`} spaceStackStart="s" className={`${button.type} ${button.inverse ? 'inverse':''}`} onClick={button.click} href={button.href}>{button.text}</Button>
									}
								</Fade>
								</LayoutItem>
							)
						})
						}
					</Layout>
					:false
				}
			</div>
		)
	}
}

export default Intro;