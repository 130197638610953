import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// ui 
import { Text, Button, ThemeProvider, audiDarkTheme } from '@audi/audi-ui-react';

import Footer from './modules/Footer';

class Select extends Component {
	static propTypes = {
	    location: PropTypes.object.isRequired,
	    history: PropTypes.object.isRequired
	}
	constructor(props){
		super(props);
		let arabic = props.arabic;
		this.state = {
			region:false,
			model:false,
			showmodel:false
		}
	}
	componentDidMount(){
		window.location.href = 'https://www.audi-dubai.com/me/web/duen.html';
	}
	region = (region) => {
		this.setState({region:region,showmodel:true});
	}
	model = (model) => {
		let plusar = this.props.arabic ? 'ar':'';
		this.setState({model:model},()=>{
			window.location.href = `/${this.state.model}/${this.state.region}/${plusar}`
		});
	}
	render(){
		const { arabic } = this.props;
		
		return (
			<div className="container" dir={arabic ? 'rtl':'ltr'} lang={arabic ? 'ar':'en'}>
				<div className="content">
				<ThemeProvider theme={audiDarkTheme}>
					<div className="selection">
					<div className="center">
					{
					1 === 2 && 
						<div className="region-select">
							<Text as="h2" variant="order2" spaceStackEnd="xl">{arabic ? 'اختر موقعك':'Select your region'}</Text>
							<nav>
								<Button variant="text" onClick={()=>{
									this.region('abudhabi');
								}}>{arabic ? 'أبو ظبي':'Abu Dhabi'}</Button>
								<Button variant="text" onClick={()=>{
									this.region('bahrain');
								}}>{arabic ? 'مملكة البحرين':'Bahrain'}</Button>
								<Button variant="text" onClick={()=>{
									this.region('dubai');
								}}>{arabic ? 'دبي':'Dubai'}</Button>
								<Button variant="text" onClick={()=>{
									this.region('kuwait');
								}}>{arabic ? 'الكويت':'Kuwait'}</Button>
								<Button variant="text" onClick={()=>{
									this.region('saudiarabia');
								}}>{arabic ? 'المملكة العربية السعودية':'Saudi Arabia'}</Button>
								<Button variant="text" onClick={()=>{
									this.region('oman');
								}}>{arabic ? 'سلطنة عمان':'Oman'}</Button>
								<Button variant="text" onClick={()=>{
									this.region('qatar');
								}}>{arabic ? 'قطر':'Qatar'}</Button>
								<Button variant="text" onClick={()=>{
									this.region('jordan');
								}}>{arabic ? 'الأردن':'Jordan'}</Button>
								<Button variant="text" onClick={()=>{
									this.region('lebanon');
								}}>{arabic ? 'لبنان':'Lebanon'}</Button>
							</nav>	
						</div>
					}
					{
						this.state.showmodel && 1 === 2 &&
						<div className="model-select">
							<Text as="h2" variant="order2" spaceStackEnd="xl">{arabic ? 'الطراز المفضل':'Select your preferred model'}</Text>
							<nav>
								<Button variant="text" onClick={()=>{
									this.model('gt');
								}}>Audi e-tron GT quattro</Button>
								<Button variant="text" onClick={()=>{
									this.model('gtrs');
								}}>Audi RS e-tron GT</Button>
							</nav>	
						</div>
					}
					</div>
					</div>
				</ThemeProvider>
				</div>
			</div>
		);
	}
}

// export with redux connect
export default withRouter(Select);

