import React, { Component,Fragment } from 'react';

// redux
import { connect } from 'react-redux';

// ui
import { Text,Layout,LayoutItem,Button } from '@audi/audi-ui-react';

// plugins
import {Words} from '../plugins/Words';
import Fade from 'react-reveal/Fade';

class DualTeaser extends Component {
	constructor(props){
		super(props);
		this.state = {
			inview:false
		}
		this.video = React.createRef();
		this.player = React.createRef();
		this.item = React.createRef();
	}
	componentDidMount(){
		if(this.video.current){	
			this.video.current.addEventListener('play',(e) => {
				let videos = document.querySelectorAll('video');
				videos.forEach((video) => {
					if(video.getAttribute('src') !== this.video.current.getAttribute('src')){
						video.pause();
					}
				})
			})
			this.video.current.onended = () => {
				this.player.current.classList.remove('playing');
			}
		}

		let options = {
			root: null,
			rootMargin: '0px',
		  	threshold: 0
		}
		if(this.item.current){
			let observer = new IntersectionObserver(this.observe,options);
			observer.observe(this.item.current);
		}
	}
	observe = (entries, observer) => {
		if(entries[0].isIntersecting){
			this.setState({inview:true})
		}else {
			// observer.unobserve(this.text.current);
		}
	}
	playVideo = () => {
		let players = document.querySelectorAll('.play-video:not(.in-banner),.center-play');
			players.forEach((player) => {
				player.classList.remove('playing')
			})
		this.video.current.play();
		this.player.current.classList.add('playing');
		this.props.pauseState();
	}
	pause = () => {
		this.video.current.pause();
		this.player.current.classList.remove('playing');
	}
	render(){
		const { img, title, body, buttonText, reverse, mobi, onClick, className,video } = this.props;
		let basis = mobi ? '100%':'50%',
			contentProps = mobi ? {
				spaceStackStart:'xxl',
				spaceStackEnd:'xxl'
			} : {
				spaceStackStart:'xxl',
				spaceStackEnd:'xxl',
				spaceInlineStart:'xxl',
				spaceInlineEnd:'xxl'
			}
		// render
		return ( 
			<div className={`module dual-teaser ${className ? className:''} ${this.state.inview ? 'inview':''}`} ref={this.item}>
				{
					reverse ?
					<Layout wrap="wrap" className="dual-reverse">
						<LayoutItem grow="0" basis={basis} align="center">
							<LayoutItem {...contentProps}>
								<Text as="h1" variant="order1" weight="bold" spaceStackEnd="m">
									<Words text={`${title}`} delay={0}/>
								</Text>
								<Text as="p" variant="copy1" spaceStackEnd="l">
		  							<Words text={`${body}`} delay={0.3}/>
								</Text>
								<Fade 
									bottom 
									delay={1200}
									duration={1000}
									distance={'60%'}>
									{
										this.props.form.name !== '' && this.props.form.surname !== '' && this.props.form.email !== '' && 
										<Fade 
											bottom 
											delay={800}
											duration={1000}
											distance={'40%'}>
											<Button variant="primary" className="cta" onClick={onClick}>{buttonText}</Button>
										</Fade>
									}
								</Fade>
							</LayoutItem>
						</LayoutItem>
						<LayoutItem grow="0" basis={basis} className="video-holder">
							<Fade 
								bottom 
								duration={1000}
								distance={'10%'}>
								<div>
									{
										video && 
										<Fragment>
											<video width="730" height="730" src={video} ref={this.video} poster={img} onClick={this.pause} playsInline/>
											<div className="play-video" ref={this.player} onClick={this.playVideo}>
												<svg width="48" height="48" viewBox="0 0 48 48">
												    <path className="st0" d="M19.2,14.6l13.1,8.8l-13.1,9V14.6z"/>
												</svg>
											</div>
										</Fragment>
									}
								</div>
							</Fade>
						</LayoutItem>
					</Layout>
					:
					<Layout wrap="wrap">
						<LayoutItem grow="0" basis={basis} className="video-holder">
							<Fade 
								bottom 
								duration={1000}
								distance={'10%'}>
								<div>
									{
										video && 
										<Fragment>
											<video width="730" height="730" src={video} ref={this.video} poster={img} onClick={this.pause} playsInline/>
											<div className="play-video" ref={this.player} onClick={this.playVideo}>
												<svg width="48" height="48" viewBox="0 0 48 48">
												    <path className="st0" d="M19.2,14.6l13.1,8.8l-13.1,9V14.6z"/>
												</svg>
											</div>
										</Fragment>
									}
								</div>
							</Fade>
						</LayoutItem>
						<LayoutItem grow="0" basis={basis} align="center">
							<LayoutItem {...contentProps}>
								<Text as="h1" variant="order1" weight="bold" spaceStackEnd="m">
									<Words text={`${title}`} delay={0}/>
								</Text>
								<Text as="p" variant="copy1" spaceStackEnd="l">
		  							<Words text={`${body}`} delay={0.1}/>
								</Text>
								{
									this.props.form.name !== '' && this.props.form.surname !== '' && this.props.form.email !== '' && 
									<Fade 
										bottom 
										delay={800}
										duration={1000}
										distance={'40%'}>
										<Button variant="primary" className="cta" onClick={onClick}>{buttonText}</Button>
									</Fade>
								}
							</LayoutItem>
						</LayoutItem>
					</Layout>
				}
			</div>
		)
	}
}

// connects redux store to component
function mapStateToProps(state){
  	return {
  		mobi:state.mobi,
  		form:state.form,
  	}
}

// export with redux connect
export default connect(mapStateToProps)(DualTeaser);