import React, { Component,Fragment } from 'react';

// redux
import { connect } from 'react-redux';
import { setForm } from '../redux/actions';

// axios
import axios from 'axios';

// plugins
import {Words} from '../plugins/Words';
import Fade from 'react-reveal/Fade';
import { loadReCaptcha,ReCaptcha } from 'react-recaptcha-v3';

// ui
import { 	
	ThemeProvider,
	audiDarkTheme,
	Layout, 
	LayoutItem, 
	Text, 
	FormGroup, 
	TextField, 
	Select, 
	Checkbox, 
	Button 
} from '@audi/audi-ui-react';

const ProgressIcon = (props) => {
	return (
		<div className={`progress-loader ${props.state ? props.state : ''}`}>
			<svg viewBox="0 0 80 80">
				<circle className="circle-start"  cx="40" cy="40" r="36" fill="transparent" strokeWidth="4" stroke="rgba(255,255,255,.5)"/>
			    <circle className="circle" cx="40" cy="40" r="36" fill="transparent" stroke="white" strokeWidth="4"/>
			    <circle className="circle-end" cx="40" cy="40" r="36" fill="transparent" stroke="white" strokeWidth="4"/>
		    </svg>
		    <div className="check-icon"></div>
		</div>
    );
}

class OrderForm extends Component {
	constructor(props){
		super(props);
		
		const { data, arabic, region, model, name } = this.props;
		console.log(data.sa)

		const urlParams = new URLSearchParams(window.location.search);
		const entries = urlParams.entries();
		let modelName = name;

		let modelParams = false,
			configColor = urlParams.has('color') ? urlParams.get('color'):'',
			configWheel = urlParams.has('wheel') ? urlParams.get('wheel'):'',
			configInterior = urlParams.has('interior') ? urlParams.get('interior'):'';

			if(urlParams.has('color') || urlParams.has('wheel') || urlParams.has('interior')){
				modelParams = {
					color:configColor,
					wheel:configWheel,
					interior:configInterior
				}
			}

		this.state = {
			data:data,
			arabic:arabic,
			region:region,
			model:model,
			form:{
				title:'',
				name:'',
				surname:'',
				email:'',
				phone:'',
				country:region === 'me' ? '':region,
				city:region === 'me' ? '' : data[region][arabic ? 'ar':'en'].cities.length > 1 ? '':data[region][arabic ? 'ar':'en'].cities[0].value,
				consent:'consent',
				config:modelParams ? modelParams : ''
			},
			ready:false,
			busy:false,
			sent:false,
			ip:'',
			token:false,
			prepareThanks:false,
			phoneMsg:'',
			emailErr:false,
			phoneErr:false,
			singleCity:region !== 'az' && region !== 'du' && region !== 'sa' ? true:false,
			locale:{
				intro:{
					title:{
						ar:`يُعتبر المخزون المحدود مسألة ثانوية في الطّريق لامتلاك ذروة أداء سيارات Audi. <br/> لهذا السّبب، نُشجعك على تجنب إضاعة الفرصة، عبر حجز نسختك من سيارة ${modelName} والانضمام إلى قائمة الانتظار قبل نفاذ الكمية المخصّصة لأسواقنا.`,
						en:`Limited stock is unfortunately a byproduct of owning the pinnacle of Audi performance. <br/> For this reason, we encourage you to reserve your ${modelName} and join the wait list before regional allocation is exhausted to avoid disappointment.`
					},
					subtitle:{
						ar:'أكمل النّموذج أدناه للمتابعة.',
						en:'Complete the form below to proceed.'
					}
				},
				title:{
					text:{
						ar:'اللقب',
						en:'Title'
					},
					options:{
						ar:['السيد','السيدة','الآنسة'],
						en:['Mr','Mrs','Miss']
					}
				},
				name:{
					text:{
						ar:'الاسم الأول',
						en:'First name'
					}
				},
				surname:{
					text:{
						ar:'اسم العائلة',
						en:'Last name'
					}
				},
				email:{
					text:{
						ar:'البريد الإلكتروني',
						en:'Email address'
					},
					error:{
						ar:'يرجى ادخال عنوان البريد الإلكتروني الصحيح.',
						en:'Please enter a correct Email address'
					}
				},
				code:{
					text:{
						ar:'كود',
						en:'Code'
					}
				},
				phone:{
					text:{
						ar:'رقم الهاتف المتحرك',
						en:'Mobile number'
					},
					pre:{
						ar:'أولا يجب عليك اختيار بلد',
						en:'Select a country first'
					}
				},
				country:{
					text:{
						ar:'البلد',
						en:'Country'
					}
				},
				city:{
					text:{
						ar:'المدينة',
						en:'City'
					}
				},
				purchaseDate:{
					text:{
						ar:'جدولة الشّراء',
						en:'Schedule Purchase'
					},
					options:{
						ar:['في أقرب وقت ممكن','في غضون 3 أشهر','في غضون 6 أشهر','خلال سنة واحدة'],
						en:['As soon as possible','within 3 months','within 6 months','within 1 year']
					}
				},
				submit:{
					text:{
						ar:'ارسل الطلب',
						en:'Send request'
					},
					processing:{
						ar:'أرجو الإنتظار',
						en:'Processing'
					},
					sent:{
						ar:'أرسلت بنجاح',
						en:'Success'
					}
				},
				consent:{
					ar:'لا أرغب في تلقي مواد ترويجية أو تسويقية متعلّقة بما ورد أعلاه.',
					en:'I do not wish to receive promotional or marketing material related to the above.'
				},
				fine:{
					ar:<span>يمكن استخدام جميع البيانات الشّخصية المقدمة من خلال هذا النّموذج والاحتفاظ بها بواسطة Audi AG و Audi Volkswagen Middle East FZE ووكلائها المعتمدين ومقدمي خدماتها لأغراضٍ ترويجية أو تسويقية. لا ينطبق إلغاء الاشتراك من النّموذج أعلاه على الأنواع الأخرى من الاتصالات التّسويقية التّرويجية. الاتصال: <a href="mailto:rsvp@audi.avme.ae" style={{display:'inline-block'}}>rsvp@audi.avme.ae</a></span>,
					en:<span>All personal data provided through this form may be used and retained by Audi AG, Audi Volkswagen Middle East FZE, its authorized dealers and its service providers for promotional or marketing purposes. Unsubscribing from the above form does not apply to other types of promotional marketing communication. <b>Contact: <a href="mailto:rsvp@audi.avme.ae" style={{display:'inline-block'}}>rsvp@audi.avme.ae</a></b></span>
				}
			}
		}

		axios({
	        method: 'get',
	        url: 'https://www.cloudflare.com/cdn-cgi/trace',
	    }).then((res)=>{
	    	let ip = res.data.substring(
			    res.data.lastIndexOf("ip=") + 3, 
			    res.data.lastIndexOf("ts=")
			);
			this.setState({ip: ip.replace(/[^0-9\.]+/g, '')}) 
	    })
	}
	verifyCallback = (recaptchaToken) => {
    	this.setState({token:recaptchaToken});
  	}
	componentDidMount(){	
		loadReCaptcha('6LejBH4UAAAAAEvv13_E_HPzvMw5k8F9DKdA3S2U');
		let svg = document.querySelector('.progress-loader svg');
		svg.addEventListener("animationiteration",this.animationHandle);
	}
	animationHandle = () => {
		const {sent} = this.state;
		if(sent){
			let svg = document.querySelector('.progress-loader svg');
			svg.classList.add('stop-animation');
		}
	}
	onConsent = (e) => {
		const { form } = this.state;
		if(document.querySelector('#consent-click').checked){
			form.consent = 'noconsent';
		}else {
			form.consent = 'consent';
		}
		this.setState({form:form});
	}
	onChange = (e) => {
		const { data,form,arabic } = this.state;
		const { name,value } = e.currentTarget;
		form[name] = value;
		if(form.country !== ''){
			this.setState({phoneMsg:data[form.country][arabic ? 'ar':'en'].phoneFormat});
		}
		if(name === 'country'){
			if(form.country !== ''){
				if(form.country !== 'az' && form.country !== 'du' && form.country !== 'sa'){
					form.city = data[form.country][arabic ? 'ar':'en'].cities[0].value;
					this.setState({singleCity:true})
				}else {
					form.city = '';
					this.setState({singleCity:false})
				}
				form.phone = '';
			}
		}
		this.setState({form:form});
		this.validate(name);
	}
	validateNumber = (number) => {
		const { data,form,arabic } = this.state;
		//UAE
		if(data[form.country][arabic ? 'ar':'en'].code === '971'){
			if(number.length === 9 && number.match("^5")){
				return true;
			}
			else {
				return false;
			}
		}
		// Bahrain
		if(data[form.country][arabic ? 'ar':'en'].code === '973'){
			if(number.length > 5 && number.length < 9){
				return true;
			}
			else {
				return false;
			}
		}
		//Kuwait
		else if(data[form.country][arabic ? 'ar':'en'].code === '965'){
			if(number.length > 5 && number.length < 11){
				return true;
			}
			else {
				return false;
			}
		}
		//Saudi
		else if(data[form.country][arabic ? 'ar':'en'].code === '966'){
			if(number.length > 6){
				return true;
			}
			else {
				return false;
			}
		}
		//Oman
		else if(data[form.country][arabic ? 'ar':'en'].code === '968'){
			if(number.length === 8){
				return true;
			}
			else {
				return false;
			}
		}
		//Qatar
		else if(data[form.country][arabic ? 'ar':'en'].code === '974'){
			if(number.length > 6 && number.length < 9){
				return true;
			}
			else {
				return false;
			}
		}
		//Jordan
		else if(data[form.country][arabic ? 'ar':'en'].code === '962'){
			if((number.length === 8 && number.match('^6')) || (number.length === 9 && number.match('^7'))){
				return true;
			}
			else {
				return false;
			}
		}
		// Lebanon
		else if(data[form.country][arabic ? 'ar':'en'].code === '961'){
			if(number.length > 6 && number.length < 9){
				return true;
			}
			else {
				return false;
			}
		}
	}
	validate = (name) => {
		const { form } = this.state,
			  reg = /^(?!.*\.{2})([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

		// validate
		let empty = false,
			email = false,
			phone = false;

		for(const [key, value] of Object.entries(form)){
		  	if(value === '' && key !== 'config'){
		  		empty = true;
		  	}
		  	// email
		  	if(key === 'email'){
		  		if(name === 'email'){
		  			if(!reg.test(form.email)){
		  				this.setState({emailErr:true})
		  			}else {
		  				this.setState({emailErr:false})
		  			}
		  		}
	  		}
	  		if(!reg.test(form.email)){
  				email = true;
  			}else {
  				email = false;
  			}
	  		// phone
	  		if(key === 'phone'){
		  		if(name === 'phone'){	
		  			if(!this.validateNumber(form.phone)){
		  				this.setState({phoneErr:true})
		  			}else {
						this.setState({phoneErr:false})
		  			}
		  		}
	  		}
	  		if(form.country !== ''){
	  			if(!this.validateNumber(form.phone)){
	  				phone = true;
	  			}else {
	  				phone = false;
	  			}
	  		}
		}
		if(!empty && !email && !phone){
			this.setState({ready:true})
			return false;
		}
		this.setState({ready:false})
	}
	submit = () => {
		// params
		const urlParams = new URLSearchParams(window.location.search);
		const entries = urlParams.entries();
		let params = '',
			campaign = urlParams.has('campaign') ? urlParams.get('campaign'):'',
			type = 'pre-order';
		for(const entry of entries){
			if(entry[0] !== 'campaign' && entry[0] !== 'scroll' && entry[0] !== 'm' && entry[0] !== 'r' && entry[0] !== 'color' && entry[0] !== 'wheel' && entry[0] !== 'interior'){
				params += '['+entry[0]+'='+entry[1]+']';
			}
		}

		const { ready, arabic, form, ip, model, token } = this.state;
		if(ready){

			this.setState({busy:true});
		
			let region = this.props.region;
			let restURL = 'https://rest.audimiddleeast.com/forms/waitlist/gt/index.php';

			axios({
		        method: 'post',
		        responseType: 'json',
		        url: restURL,
		        data: {
		        	model:model,
		        	title:form.title,
				    name:form.name,
				    surname:form.surname,
				    email:form.email,
				    phone:form.phone,
				    country:form.country.toUpperCase(),
				    city:form.city,
					language:arabic ? 'ar':'en',
					tracking:params,
					source:document.referrer,
					ip:ip,
			  		token:token,
			  		type:type,
			  		consent:form.consent,
			  		config:form.config
			  	},
			  	headers: {
			  		'Authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT'
			  	}
		    }).then((res)=>{
				// alert(JSON.stringify(res.data))
		    	let msg = res.data.message,
		    		token = res.data.token;
		    	if(msg === 'recaptcha'){
		    		alert("Error, your session has expired, please reload to submit again");
		    		window.location.reload();
		    	}else if(msg === 'not authorized'){
		    		alert("Error, not authorized");
		    		window.location.reload();
		    	}else if(msg === 'response type invalid'){
		    		alert("Error, response type invalid");
		    		window.location.reload();
		    	}else if(msg === 'empty'){
		    		alert("Error, one or more fields is empty, please try again");
		    		window.location.reload();
		    	}else if(msg === 'email'){
		    		alert("Error, your email is invalid, please try again");
		    		window.location.reload();	
		    	}else if(msg === 'number'){
		    		alert("Error, invalid number format, please try again");
		    		window.location.reload();
		    	}else if(msg === 'success' || msg === 'exists'){
		    		// run thanks
		    		this.setState({busy:false,sent:true})
					setTimeout(()=>{
						this.props.setForm(model,form.title,form.name,form.surname,form.email);
						this.props.loadThanks();
					},1400)
		    	}else {
		    		alert("Somethings not right: please try again later");
		    		window.location.reload();
		    	}
		    }).catch((err)=>{
		    	alert("Somethings not right: please try again later");
		    	window.location.reload();
		    }) 

		}
	}

	render(){
		const { 
			data,
			region,
			arabic,
			form,
			phoneMsg,
			ready,
			busy,
			sent,
			prepareThanks,
			emailErr,
			phoneErr,
			locale,
			singleCity,
		} = this.state;

		const { mobi, purchase } = this.props;

		let countries = Object.entries(data),
			currentcode = form.country !== '' ? data[form.country][arabic ? 'ar':'en'].code:'ext',
			basis = mobi ? '100%':'50%',
			emailBasis = `${basis}`, 
			leftSpace = !mobi ? {
				spaceInlineEnd:'s'
			}:false,
			rightSpace = !mobi ? {
				spaceInlineStart:'s'
			}:false,
			citySpace = !mobi ?
				region === 'me' ? {
					spaceInlineStart:'s'
				}:{
					spaceInlineEnd:'s'
				}
			:false,
			titleSpace = !mobi ? 
				region !== 'me' && !singleCity ? {
					spaceInlineStart:'s'
				}: {
					spaceInlineEnd:'s'
				}
			: false,
			stackSpace = !mobi ? {
				spaceStackEnd:'xl'
			}:false,
			mobiFieldSpace = mobi ? {
				spaceStackEnd:'m'
			}:false;
		return (
			<ThemeProvider theme={audiDarkTheme}>
				<div className={`module form ${prepareThanks ? 'fade-it':''}`}>
					<div className="inner">
						<Text as="h4" variant={mobi ? 'copy1':'order4'} spaceStackEnd="xxl" className="center">
							<Words text={locale.intro.title[arabic ? 'ar':'en']} delay={0}/>
						</Text>
						<Text as="h4" variant={mobi ? 'copy1':'order4'} spaceStackEnd="xxl" weight="bold" className="center">
							<Words text={locale.intro.subtitle[arabic ? 'ar':'en']} delay={.2}/>
						</Text>
						<form>
						{/*form cols*/}
						<Layout direction={mobi ? 'column':'row'} wrap="wrap" className="form-fields">
							<Layout className="form-title hidden" spaceStackEnd="l">
								<Fade bottom delay={500} duration={1000} distance={'50%'}>
									<Text as="h4" variant="order4" weight="bold">
										Personal Info
									</Text>
								</Fade>
							</Layout>
							<Layout direction={mobi ? 'column':'row'} className="form-group" {...stackSpace}>
								{ region !== 'me' && !singleCity ? 
									<LayoutItem grow="0" shrink="0" basis={basis} {...mobiFieldSpace}>
										<Fade bottom delay={500} duration={1000} distance={'50%'}>
											<Select
											    inputId="city"
											    name="city"
											    label={locale.city.text[arabic ? 'ar':'en']}
											    value={form.city}
											    required
											    invalid={false}
											    {...leftSpace}
											    onChange={this.onChange}
												>
												{
													form.country !== '' ? 
														data[form.country][arabic ? 'ar':'en'].cities.map((city,k)=>{
															return <option value={city.value} key={k}>{city.name}</option>	
														})
													: false
												}
											</Select>
										</Fade>
									</LayoutItem>
								: false }
								<LayoutItem grow="0" shrink="0" basis={basis} {...mobiFieldSpace}>
									<Fade bottom delay={500} duration={1000} distance={'50%'}>
										<Select
										    inputId="title"
										    name="title"
										    label={locale.title.text[arabic ? 'ar':'en']}
										    value={form.title}
										    required
										    invalid={false}
										    {...titleSpace}
										    onChange={this.onChange}
											>
											<option value="mr">{locale.title.options[arabic ? 'ar':'en'][0]}</option>
											<option value="mrs">{locale.title.options[arabic ? 'ar':'en'][1]}</option>
											<option value="miss">{locale.title.options[arabic ? 'ar':'en'][2]}</option>
										</Select>
									</Fade>
								</LayoutItem>
							</Layout>
							<Layout direction={mobi ? 'column':'row'} className="form-group" {...stackSpace}>
								<LayoutItem grow="0" basis={basis} {...mobiFieldSpace}>
									<Fade bottom delay={500} duration={1000} distance={'50%'}>
										<TextField
										    inputId="name"
										    name="name"
										    type="text"
										    label={locale.name.text[arabic ? 'ar':'en']}
										    value={form.name}
										    required
										    invalid={false}
										    {...leftSpace}
										    onChange={this.onChange}
											/>
									</Fade>
								</LayoutItem>
								<LayoutItem grow="0" basis={basis} {...mobiFieldSpace}>
									<Fade bottom delay={500} duration={1000} distance={'50%'}>
										<TextField
										    inputId="surname"
										    name="surname"
										    type="text"
										    label={locale.surname.text[arabic ? 'ar':'en']}
										    value={form.surname}
										    required
										    invalid={false}
										    {...rightSpace}
										    onChange={this.onChange}
											/>
									</Fade>
								</LayoutItem>
							</Layout>
							<Layout direction={mobi ? 'column':'row'} className={`form-group ${region !== 'me' ? 'hidden':''}`} {...stackSpace}>
								{ region === 'me' ?
									<Fragment>
										<LayoutItem grow="0" basis={basis} {...mobiFieldSpace}>
											<Fade bottom delay={500} duration={1000} distance={'50%'}>
												<Select
												    inputId="country"
												    name="country"
												    label={locale.country.text[arabic ? 'ar':'en']}
												    value={form.country}
												    required
												    invalid={false}
												    {...leftSpace}
												    onChange={this.onChange}
													>
													{
														countries.map((country,k)=>{
															return <option value={country[0]} key={k}>{country[1][arabic ? 'ar':'en'].name}</option>	
														})
													}
												</Select>
											</Fade>
										</LayoutItem>
										{ !singleCity ? 
											<LayoutItem grow="0" shrink="0" basis={basis} {...mobiFieldSpace}>
												<Fade bottom delay={500} duration={1000} distance={'50%'}>
													<Select
													    inputId="city"
													    name="city"
													    label={locale.city.text[arabic ? 'ar':'en']}
													    value={form.city}
													    required
													    invalid={false}
													    {...citySpace}
													    onChange={this.onChange}
														>
														{
															form.country !== '' ? 
																data[form.country][arabic ? 'ar':'en'].cities.map((city,k)=>{
																	return <option value={city.value} key={k}>{city.name}</option>	
																})
															: false
														}
													</Select>
												</Fade>
											</LayoutItem>
										: false }
									</Fragment>
								: false }
							</Layout>
							<Layout direction={mobi ? 'column':'row'} className="form-group" {...stackSpace}>
								<LayoutItem grow="0" basis={emailBasis} {...mobiFieldSpace}>
									<Fade bottom delay={500} duration={1000} distance={'50%'}>
										<TextField
										    inputId="email"
										    name="email"
										    type="email"
										    label={locale.email.text[arabic ? 'ar':'en']}
										    value={form.email}
										    required
										    invalid={emailErr ? true:false}
										    {...leftSpace}
										    validationMessage={locale.email.error[arabic ? 'ar':'en']}
										    onChange={this.onChange}
											/>
									</Fade>
								</LayoutItem>
								<LayoutItem grow="0" basis={basis} {...mobiFieldSpace}>
									<FormGroup invalid={phoneErr ? true:false} id="mobile-phone" validationMessage={phoneMsg}>
										<Fade bottom delay={500} duration={1000} distance={'50%'}>
											<Layout className="code-group">
												<LayoutItem align="center" shrink="0" basis="60px" id="code-group">
													<TextField
													    inputId="code"
													    name="code"
													    type="text"
													    label={locale.code.text[arabic ? 'ar':'en']}
													    value={`+${currentcode}-`}
													    {...leftSpace}
													    {...rightSpace}
													    required
														/>
												</LayoutItem>
												<LayoutItem grow="0" shrink="0" basis={mobi ? 'calc(100% - 60px)':'calc(100% - 89px)'}>
													<TextField
													    inputId="phone"
													    name="phone"
													    type="tel"
													    label={form.country === '' ? locale.phone.pre[arabic ? 'ar':'en']:locale.phone.text[arabic ? 'ar':'en']}
													    value={form.phone}
													    pattern="[0-9.]*"
													    required
													    disabled={form.country === '' ? true:false}
													    invalid={phoneErr ? true:false}
													    validationMessage=""
													    onChange={this.onChange}
													    />
												</LayoutItem>
											</Layout>
										</Fade>
									</FormGroup>
								</LayoutItem>
							</Layout>

							<Layout className="form-title hidden" spaceStackStart="l" spaceStackEnd="l">
								<Fade bottom delay={500} duration={1000} distance={'50%'}>
									<Text as="h4" variant="order4" weight="bold">
										Pre-order preferences
									</Text>
								</Fade>
							</Layout>
							<Layout align="center" justify="around" spaceStackEnd={mobi ? 'xl':'s'} spaceStackStart="m">
								<LayoutItem grow="0" shrink="0" basis="100%">
									<Fade bottom delay={500} duration={1000} distance={'50%'}>
										<Checkbox 
										 	inputId="consent-click" 
										 	id="consent" 
										 	name="consent" 
										 	required
										 	onChange={this.onConsent}
										 	>
										 	{locale.consent[arabic ? 'ar':'en']}
										</Checkbox>
									</Fade>
								</LayoutItem>
							</Layout>
							<LayoutItem grow="1" basis="100%">
								<Fade bottom delay={500} duration={1000} distance={'50%'}>
								<div className="form-btns">
									<Button 
										variant="primary" 
										disabled={ready && !busy ? false:true}
										id="submit-btn"
										className={busy || sent ? 'processing':''}
										icon={!busy && !sent ? <ProgressIcon state="mail"/>:!sent ? <ProgressIcon state="process"/>:<ProgressIcon state="process done"/>}
										onClick={this.submit}
										>
								      	{
									      	busy ? locale.submit.processing[arabic ? 'ar':'en']
									      	: sent ? locale.submit.sent[arabic ? 'ar':'en']
									      	:locale.submit.text[arabic ? 'ar':'en']
								      	}
								    </Button>
								</div>
								</Fade>
							    {
							    	<ReCaptcha
							            sitekey="6LejBH4UAAAAAEvv13_E_HPzvMw5k8F9DKdA3S2U"
							            action='submit_form'
							            verifyCallback={this.verifyCallback}
							        />
							    }
						    </LayoutItem>
						</Layout>
						{/*fineprint*/}
						<Layout align="start" direction="column" justify="start" spaceStackStart="xl">
							<Fade bottom delay={500} duration={1000} distance={'50%'}>
								<Text as="p" variant="copy3" id="fineprint">
									{locale.fine[arabic ? 'ar':'en']}<br/>
								</Text>
							</Fade>
						</Layout>
						</form>
					</div>
				</div>
			</ThemeProvider>
		);
	}
}

// connects redux store to component
function mapStateToProps(state){
  	return {
  		arabic:state.arabic,
  		data:state.data,
  		mobi:state.mobi
  	}
}

// to make redux actions as props
const mapDispatchToProps = {
	setForm
}

// export with redux connect
export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);