export const setMobi = (value) => ({ type: 'MOBI',payLoad: value });
export const setVerify = (value) => ({ type: 'VERIFY',payLoad: value });
export const setForm = (model,title,name,surname,email,phone,country,city,purchaseDate,contactTime,language,tracking,source,ip,type,consent) => ({ type: 'FORM',payLoad: {
		model:model,
    	title:title,
	    name:name,
	    surname:surname,
	    email:email,
	    phone:phone,
	    country:country,
	    city:city,
	    purchaseDate:purchaseDate,
	    contactTime:contactTime,
		language:language,
		tracking:tracking,
		source:source,
		ip:ip,
  		type:type,
  		consent:consent
	} 
});
export const setCheckout = (value) => ({ type: 'CHECKOUT',payLoad: value });
export const setPaid = (value) => ({ type: 'PAID',payLoad: value });
export const setEmailRef = (value) => ({ type: 'EMAIL_REF',payLoad: value });