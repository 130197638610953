import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// redux
import { connect } from 'react-redux';
import { setVerify } from './redux/actions';
// ui 
import { 	
	ThemeProvider,
	audiDarkTheme,
	audiLightTheme,
	Layout, 
	LayoutItem, 
	Text, 
	FormGroup, 
	TextField, 
	Select, 
	Checkbox, 
	Button 
} from '@audi/audi-ui-react';


// modules
import Intro from './modules/Intro';
import Footer from './modules/Footer';

// plugins
import axios from 'axios';

import Cards from 'react-credit-cards';


const ProgressIcon = (props) => {
	return (
		<div className={`progress-loader ${props.state ? props.state : ''}`}>
			<svg viewBox="0 0 80 80">
				<circle className="circle-start"  cx="40" cy="40" r="36" fill="transparent" strokeWidth="4" stroke="rgba(255,255,255,.5)"/>
			    <circle className="circle" cx="40" cy="40" r="36" fill="transparent" stroke="white" strokeWidth="4"/>
			    <circle className="circle-end" cx="40" cy="40" r="36" fill="transparent" stroke="white" strokeWidth="4"/>
		    </svg>
		    <div className="check-icon"></div>
		</div>
    );
}

class ErrorCheckout extends Component {
	static propTypes = {
	    location: PropTypes.object.isRequired,
	    history: PropTypes.object.isRequired
	}

	constructor(props){
		super(props);

		let arabic = this.props.arabic;
		this.state = {
			message:false
		}
	}

	componentDidMount(props){
		const urlParams = new URLSearchParams(window.location.search);
		if(urlParams.has('m')){
			let message = urlParams.get('m');
			this.setState({message:message})
		}
	}

	render(){
		const { arabic, mobi, modelSlug } = this.props;
		let message = this.state.message;
		let checkoutUrl = arabic ? modelSlug === 'gt' ? '/gt/ar/checkout':'/gtrs/ar/checkout':modelSlug === 'gt' ? '/gt/checkout':'/gtrs/checkout',
			cancelUrl = arabic ? modelSlug === 'gt' ? 'https://ar.audimiddleeast.com/duar/models/e-tron-gt' : 'https://ar.audimiddleeast.com/duar/models/rs-e-tron-gt':modelSlug === 'gt' ? 'https://audi-dubai.com/me/web/duen/models/e-tron-gt/e-tron-gt.html' : 'https://audi-dubai.com/me/web/duen/models/e-tron-gt/rs-e-tron-gt.html';
		return (
			<div className="checkout-container container" dir={arabic ? 'rtl':'ltr'} lang={arabic ? 'ar':'en'}>
				<ThemeProvider theme={audiDarkTheme}>
					<div className="checkout-summary">
						<Intro 
							text={arabic ? "معاملة غير ناجحة":"Transaction unsuccessful."}
							body={arabic ? "شكرًا لك على طلبك المسبق، للأسف لم نتمكّن من معالجة عملية الدّفع. <br/> للمحاولة مرة أخرى، يرجى النّقر فوق الرّابط أدناه وتعبئة عنوان بريدك الإلكتروني.":"Thank you for your pre-order request, unfortunately we were not able to process the payment. <br/> In order to try again, please click the link below and fill in your email address."}
							error={message}
							buttons={[{text:arabic ? 'إعادة محاولة الشّراء':'Retry purchase',href:checkoutUrl,type:'primary'},{text:[arabic ? 'إلغاء الطّلب':'Cancel request'],href:cancelUrl,type:'secondary'}]}/>
							<br/>
							<Text as="h2" variant="order2" spaceStackEnd="xl"></Text>
					</div>	
				</ThemeProvider>
			</div>
		);
	}
}

// connects redux store to component
function mapStateToProps(state){
  	return {
  		mobi:state.mobi
  	}
}

// export with redux connect
export default withRouter(connect(mapStateToProps)(ErrorCheckout));

