import React, { Component,Fragment } from 'react';

// redux
import { connect } from 'react-redux';

// ui
import { Layout,LayoutItem,Text,Button } from '@audi/audi-ui-react';

// plugins
import {Words} from '../plugins/Words';
import Fade from 'react-reveal/Fade';

class Banner extends Component {

	constructor(props){
		super(props);
		this.state = {
			color:props.region === 'du' ? 'red' : props.region === 'az' ? 'blue':'gold'
		}
	}
	componentDidMount(){
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
	render(){
		const { mobi,title,body,buttons,model } = this.props;
		let color = this.state.color;
		// render
		return ( 
			<div className="module banner">
				<div className="bg">
					<picture>
					  	<source srcSet={`${process.env.PUBLIC_URL}/assets/img/hero/${model}-hero-desktop-${color}.jpg`} media="(min-width: 768px)"/>
					  	<img src={`${process.env.PUBLIC_URL}/assets/img/hero/${model}-hero-mobi-${color}.jpg`} alt="Audi e-tron GT"/>
					</picture>
					<div className="banner-content">
						{
							title ? 
								<Text as="h1" variant="order1" weight="bold" className="white thin-line">
									<Words text={title} delay={0}/>
								</Text>
							:false
						}
						{
							body ? 
								<Text as="h4" variant={mobi ? 'copy1':'order4'} spaceStackStart="m" spaceStackEnd="xl" className="white">
		  							<Words text={body} delay={0.4}/>
								</Text>
							:false
						}
						{
							buttons && buttons.length ?
							<Layout className="button-group">
								{
								buttons.map((button,b)=>{
									return (
										<LayoutItem key={b} spaceInlineEnd="s">
										<Fade 
											bottom 
											delay={1000}
											duration={1000}
											distance={'60%'}>
											<Button variant={`${button.type}`} spaceStackStart="s" className={`${button.type}`} onClick={button.click} href={button.href}>{button.text}</Button>
										</Fade>
										</LayoutItem>
									)
								})
								}
							</Layout>
							:false
						}
					</div>
				</div>
			</div>
		)
	}
}
// connects redux store to component
function mapStateToProps(state){
  	return {
  		mobi:state.mobi
  	}
}

// export with redux connect
export default connect(mapStateToProps)(Banner);