import React, { Component,Fragment } from 'react';

// redux
import { connect } from 'react-redux';

import Popup from 'reactjs-popup';

// ui
import { Layout,LayoutItem,Text,Button } from '@audi/audi-ui-react';

// plugins
import { FaFacebookF,FaYoutube,FaTwitter,FaInstagram,FaLinkedin } from 'react-icons/fa';

class Footer extends Component {

	constructor(props){
		super(props);
		let region = props.region;
		if(region === 'az'){
			this.state = {
				popup:false,
				facebook:'https://www.facebook.com/AudiAbuDhabi/',
				youtube:'https://www.youtube.com/user/AudiAbuDhabi/',
				twitter:'https://twitter.com/audiabudhabi',
				instagram:'https://www.instagram.com/audi.abudhabi',
				linkedin:'https://www.linkedin.com/company/audi-middle-east'
			}
		}else if(region === 'bh'){
			this.state = {
				popup:false,
				facebook:'https://www.facebook.com/audibahrain/',
				youtube:'https://www.youtube.com/user/AudiMiddleEast',
				twitter:'https://twitter.com/AudiMiddleEast',
				instagram:'https://www.instagram.com/audibahrain',
				linkedin:'https://www.linkedin.com/company/audi-middle-east'
			}
		}else if(region === 'du'){
			this.state = {
				popup:false,
				facebook:'https://www.facebook.com/Audi-Al-Nabooda-685722114824085/',
				youtube:'https://www.youtube.com/channel/UC8g1gQyeLOh5_J6y51Yom0A',
				twitter:'https://twitter.com/Audi_AlNabooda',
				instagram:'https://www.instagram.com/audialnabooda',
				linkedin:'https://www.linkedin.com/company/audi-middle-east'
			}
		}else if(region === 'kw'){
			this.state = {
				popup:false,
				facebook:'https://www.facebook.com/AudiKuwait/',
				youtube:'https://www.youtube.com/user/AudiMiddleEast',
				twitter:'https://twitter.com/AudiKuwait',
				instagram:'https://www.instagram.com/audikuwait',
				linkedin:'https://www.linkedin.com/company/audi-middle-east'
			}
		}else if(region === 'sa'){
			this.state = {
				popup:false,
				facebook:'https://www.facebook.com/AudiSaudiArabia/',
				youtube:'https://www.youtube.com/user/AudiSaudiArabia',
				twitter:'https://twitter.com/AudiSaudiArabia',
				instagram:'https://www.instagram.com/audisaudiarabia',
				linkedin:'https://www.linkedin.com/company/audi-middle-east'
			}
		}else if(region === 'om'){
			this.state = {
				popup:false,
				facebook:'https://www.facebook.com/Audi3oman/',
				youtube:'https://www.youtube.com/user/audi3oman',
				twitter:'https://twitter.com/audi_oman',
				instagram:'https://www.instagram.com/audi.wattayah',
				linkedin:'https://www.linkedin.com/company/audi-middle-east'
			}
		}else if(region === 'qa'){
			this.state = {
				popup:false,
				facebook:'https://www.facebook.com/AudiMiddleEast',
				youtube:'https://www.youtube.com/user/AudiMiddleEast',
				twitter:'https://twitter.com/AudiMiddleEast',
				instagram:'https://www.instagram.com/audimiddleeast/',
				linkedin:'https://www.linkedin.com/company/audi-middle-east'
			}
		}else if(region === 'jo'){
			this.state = {
				popup:false,
				facebook:'https://www.facebook.com/AudiJordan',
				youtube:'https://www.youtube.com/user/AudiMiddleEast',
				twitter:'https://twitter.com/AudiJor',
				instagram:'https://www.instagram.com/Audi_Jordan/',
				linkedin:'https://www.linkedin.com/company/audi-middle-east'
			}
		}else if(region === 'lb'){
			this.state = {
				popup:false,
				facebook:'https://www.facebook.com/AudiMiddleEast',
				youtube:'https://www.youtube.com/user/AudiMiddleEast',
				twitter:'https://twitter.com/AudiMiddleEast',
				instagram:'https://www.instagram.com/audimiddleeast/',
				linkedin:'https://www.linkedin.com/company/audi-middle-east'
			}
		}else {
			this.state = {
				popup:false,
				facebook:'https://www.facebook.com/AudiMiddleEast',
				youtube:'https://www.youtube.com/user/AudiMiddleEast',
				twitter:'https://twitter.com/AudiMiddleEast',
				instagram:'https://www.instagram.com/audimiddleeast/',
				linkedin:'https://www.linkedin.com/company/audi-middle-east'
			}
		}
	}
	open = () => {
		this.setState({popup:true})
	}
	close = () => {
		this.setState({popup:false})
	}
	render(){
		const { arabic, baseurl, q4baseurl, region, modelSlug, regionSlug } = this.props;

		let switchUrl;
		if(arabic){
			switchUrl = window.location.href.replace('/ar','');
		}else {
			switchUrl = window.location.href.replace(`/${modelSlug}`,`/${modelSlug}/ar`);
		}
		let modelUrl = modelSlug === 'rs-e-tron-gt' ? 'e-tron-gt':'rs-e-tron-gt';
		// render
		return ( 
			<footer>
				<Layout justify="between" className="top-footer">
					<LayoutItem>
						<div className="footer-links">
							<a href="http://progress.audi" target="_blank">
								<Text as="p" variant="copy1" className="white">{arabic ? 'مركز أخبار Audi e-tron GT':'Audi e-tron GT news'}</Text>
							</a>
							<a href={arabic ? `${baseurl}models/${modelUrl}`:`${baseurl}models/e-tron-gt/${modelUrl}.html`} target="_blank">
								<Text as="p" variant="copy1" className="white">{modelSlug === 'rs-e-tron-gt' ? 'Audi e-tron GT quattro':'Audi RS e-tron GT'}</Text>
							</a>
						</div>
					</LayoutItem>
					<LayoutItem>
						<div className="social-links">
							<a href={this.state.facebook}><FaFacebookF/></a>
							<a href={this.state.instagram}><FaInstagram/></a>
							<a href={this.state.youtube}><FaYoutube/></a>
							<a href={this.state.twitter}><FaTwitter/></a>
							<a href={this.state.linkedin}><FaLinkedin/></a>
						</div>
					</LayoutItem>
				</Layout>
				<Layout justify="between" spaceStackStart="l" spaceStackEnd="l" className="bot-footer">
					<LayoutItem>
						<div className="left-footer">
							<Text as="p" variant="copy1" className="white">{!arabic ? '© 2022 AUDI AG. All rights reserved': '© 2022. AUDI AG. كافة الحقوق محفوظة'}</Text>
						</div>
					</LayoutItem>
					<LayoutItem>
						<a href={switchUrl} className="language-switch">
							<Text as="p" variant="copy1" className="white">{!arabic ? 'العربية':'English'}</Text>
						</a>
					</LayoutItem>
				</Layout>
			</footer>
		)
	}
}
// connects redux store to component
function mapStateToProps(state){
  	return {
  		arabic:state.arabic,
  		baseurl:state.baseurl,
  		q4baseurl:state.q4baseurl,
  		region:state.region
  	}
}

// export with redux connect
export default connect(mapStateToProps)(Footer);