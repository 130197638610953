// tracking button
// new ui check circle

import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';

// redux
import store from './redux/store';
import { Provider } from 'react-redux';

import { setMobi } from './redux/actions';

// ui
import { AudiPlatformProvider } from '@audi/audi-ui-react';

// styles
import './theme/styles.scss';

// pages
import Select from './Select';
import PreOrder from './PreOrder';
import Thanks from './Thanks';
import Complete from './Complete';
import Checkout from './Checkout';
import ErrorCheckout from './ErrorCheckout';

// gtm
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-567GCGX'
}
TagManager.initialize(tagManagerArgs)

let models = [
	{
		"name":"Audi e-tron GT quattro",
		"name_ar":"quattro GT e-tron Audi",
		"slug":"gt",
		"model":"e-tron-gt"
	},
	{
		"name":"Audi RS e-tron GT",
		"name_ar":"GT e-tron RS Audi",
		"slug":"gtrs",
		"model":"rs-e-tron-gt"
	}
],
routes = [
	{
		"name":"Abu Dhabi",
		"code":"az",
		"slug":"abudhabi",
		"purchase": true
	},
	{
		"name":"Bahrian",
		"code":"bh",
		"slug":"bahrain",
		"purchase": false
	},
	{
		"name":"Dubai",
		"code":"du",
		"slug":"dubai",
		"purchase": true
	},
	{
		"name":"Kuwait",
		"code":"kw",
		"slug":"kuwait",
		"purchase": false
	},
	{
		"name":"Saudi Arabia",
		"code":"sa",
		"slug":"saudiarabia",
		"purchase": false
	},
	{
		"name":"Oman",
		"code":"om",
		"slug":"oman",
		"purchase": false
	},
	{
		"name":"Qatar",
		"code":"qa",
		"slug":"qatar",
		"purchase": true
	},
	{
		"name":"Jordan",
		"code":"jo",
		"slug":"jordan",
		"purchase": false
	},
	{
		"name":"Lebanon",
		"code":"lb",
		"slug":"lebanon",
		"purchase": false
	}
]

const DubaiRoutes = ({ features }) => (
	models.map((m,i) => {
	  	let modelSlug = m.slug,
	  		model = m.model,
	  		modelname = m.name,
	  		modelname_ar = m.name_ar;
	  	return(
  	  		<Fragment key={i}>
  	  		<Route 
				exact 
				path={`/${modelSlug}`} 
				render={(props) => <PreOrder purchase={true} region={'du'} regionSlug={'dubai'} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar}/>}/>
			<Route 
				exact 
				path={`/${modelSlug}/ar`} 
				render={(props) => <PreOrder purchase={true} region={'du'} regionSlug={'dubai'} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} arabic={true}/>}/>
			<Route 
				exact 
				path={`/${modelSlug}/thanks`} 
				render={(props) => <Thanks purchase={true} region={'du'} regionSlug={'dubai'} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} />}/>
			<Route 
				exact 
				path={`/${modelSlug}/ar/thanks`} 
				render={(props) => <Thanks purchase={true} region={'du'} regionSlug={'dubai'} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} arabic={true}/>}/>
			<Route 
				exact
				path={`/${modelSlug}/ar/checkout`} 
				render={(props) => <Checkout purchase={true} region={'du'} regionSlug={'dubai'} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} arabic={true}/>}/>
			<Route 
				exact
				path={`/${modelSlug}/checkout`} 
				render={(props) => <Checkout purchase={true} region={'du'} regionSlug={'dubai'} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar}/>}/>

			<Route 
				exact
				path={`/${modelSlug}/ar/complete`} 
				render={(props) => <Complete purchase={true} region={'du'} regionSlug={'dubai'} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} arabic={true}/>}/>
			<Route 
				exact
				path={`/${modelSlug}/complete`} 
				render={(props) => <Complete purchase={true} region={'du'} regionSlug={'dubai'} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar}/>}/>

			<Route 
				exact
				path={`/${modelSlug}/ar/unsuccessful`} 
				render={(props) => <ErrorCheckout purchase={true} region={'du'} regionSlug={'dubai'} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} arabic={true}/>}/>
			<Route 
				exact
				path={`/${modelSlug}/unsuccessful`} 
				render={(props) => <ErrorCheckout purchase={true} region={'du'} regionSlug={'dubai'} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar}/>}/>

			</Fragment>
  	  	)
	})
);

const RegionRoutes = ({ features }) => (
	models.map((m) => {
	  	let modelSlug = m.slug,
	  		model = m.model,
	  		modelname = m.name,
	  		modelname_ar = m.name_ar;
	  	return(
    	  	routes.map((r,i) => {
    	  	  	let name = r.name,
    	  	  		code = r.code,
    	  	  		regionSlug = r.slug,
    	  	  		purchase = r.purchase;
    	  	  	return(
    	  	  		<Fragment key={i}>
    	  	  		<Route 
						exact 
						path={`/${modelSlug}/${regionSlug}`} 
						render={(props) => <PreOrder purchase={purchase} region={code} regionSlug={regionSlug} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar}/>}/>
					<Route 
						exact 
						path={`/${modelSlug}/${regionSlug}/ar`} 
						render={(props) => <PreOrder purchase={purchase} region={code} regionSlug={regionSlug} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} arabic={true}/>}/>
					<Route 
						exact 
						path={`/${modelSlug}/${regionSlug}/thanks`} 
						render={(props) => <Thanks purchase={purchase} region={code} regionSlug={regionSlug} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} />}/>
					<Route 
						exact 
						path={`/${modelSlug}/${regionSlug}/ar/thanks`} 
						render={(props) => <Thanks purchase={purchase} region={code} regionSlug={regionSlug} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} arabic={true}/>}/>
					{
						purchase &&
						<>
						<Route 
							exact
							path={`/${modelSlug}/${regionSlug}/ar/checkout`} 
							render={(props) => <Checkout purchase={purchase} region={code} regionSlug={regionSlug} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} arabic={true}/>}/>
						<Route 
							exact
							path={`/${modelSlug}/${regionSlug}/checkout`} 
							render={(props) => <Checkout purchase={purchase} region={code} regionSlug={regionSlug} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar}/>}/>
						<Route 
							exact
							path={`/${modelSlug}/${regionSlug}/ar/complete`} 
							render={(props) => <Complete purchase={purchase} region={code} regionSlug={regionSlug} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} arabic={true}/>}/>
						<Route 
							exact
							path={`/${modelSlug}/${regionSlug}/complete`} 
							render={(props) => <Complete purchase={purchase} region={code} regionSlug={regionSlug} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar}/>}/>
						<Route 
							exact
							path={`/${modelSlug}/${regionSlug}/ar/unsuccessful`} 
							render={(props) => <ErrorCheckout purchase={purchase} region={code} regionSlug={regionSlug} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar} arabic={true}/>}/>
						<Route 
							exact
							path={`/${modelSlug}/${regionSlug}/unsuccessful`} 
							render={(props) => <ErrorCheckout purchase={purchase} region={code} regionSlug={regionSlug} model={model} modelName={modelname} modelSlug={modelSlug} modelNameAR={modelname_ar}/>}/>
						</>
					}
					</Fragment>
    	  	  	)
    	  	})
    	)
	})
);

class Root extends React.Component {
	componentDidMount(){
		window.addEventListener('resize',this.handleResize);
	}
	handleResize = () => {
		store.dispatch(setMobi(window.innerWidth <= 768 ? true:false));
	}
    render(){
        return(
	  		<BrowserRouter basename={'/'}>
                <Switch>
                	<Route 
						exact 
						path={`/`} 
						render={(props) => <Select/>}/>
					<Route 
						exact 
						path={`/ar`} 
						render={(props) => <Select arabic={true}/>}/>
	                {/* <DubaiRoutes/> */}
	                <RegionRoutes/>
				</Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(
	<Provider store={store}>
		<AudiPlatformProvider>
			<Root/>
		</AudiPlatformProvider>
	</Provider>,
  	document.getElementById('root')
);

// serviceWorker.unregister();
