import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

// redux
import { connect } from 'react-redux';

// ui 
import { 
	ThemeProvider,
	audiDarkTheme,	
	Layout, 
	LayoutItem, 
	Text, 
	TextField,
	Button 
} from '@audi/audi-ui-react';

// axios
import axios from 'axios';

// modules
import Banner from './modules/Banner';
import Intro from './modules/Intro';
import DualTeaser from './modules/DualTeaser';
import Footer from './modules/Footer';

// plugins
import {Words,WordsInstant} from './plugins/Words';
import Fade from 'react-reveal/Fade';
import { scroller } from 'react-scroll';

class Complete extends Component {
	constructor(props){
		super(props);

		const { form, model, arabic, verify } = this.props;
		let modelName = arabic ? props.modelNameAR : props.modelName;

		let clientname = '';
			if(form.title !== '' && form.surname !== ''){
				let artest = /[\u0600-\u06FF]/,title = form.title;
				if(artest.test(form.surname) || arabic){
					if(form.title === 'mr'){
						title = 'السيد';
					}else if(form.title === 'mrs'){
						title = 'السيدة';
					}else {
						title = 'الآنسة';
					}
				}
				clientname = ` ${this.capitalize(title)} ${this.capitalize(form.surname)}`;
			}

		this.state = {
			proceed:verify ? true:false,
			authregion:false,
			busy:false,
			sent:false,
			introplaying:false,
			outroplaying:false,
			locale:{
				title:{
					ar:`شكرًا لك. <br/> تمّ الشّراء بنجاح.`,
					en:`Thank you. <br/> Your purchase was successful.`
				},
				subtitle:{
					ar:`أنت الآن جزء من حركة حصرية موجهة نحو مستقبل الأداء المتميز.  <br/> سيتصل بك أحد أعضاء فريق المبيعات لدينا قريبًا لمتابعة الخطوات التّالية. <br/> إذا كانت لديك أسئلة أو ترغب في معرفة المزيد، يرجى الاتصال بنا <br/> أو قم بتنزيل كُتيب ${modelName} أدناه.`,
					en:`You are now part of an exclusive movement geared towards the future of premium performance. <br/> A member of our dedicated sales team will be in contact shortly with next steps. <br/> If you have questions or would like to learn more, please contact us <br/> or download the ${modelName} brochure below.`
				},
				ctaTour:{
					ar:'تواصل معنا',
					en:'Chat with agent'
				},
				ctaBrochure:{
					ar:'تحميل الكُتيب',
					en:'Download Brochure'
				},
				ctaSocial:{
					ar:'طلب مكالمة',
					en:'Call back'
				},
				design:{
					title:{
						ar:'تصميم يُدير الرّؤوس',
						en:`Head turning design.`
					},
					body:{
						ar:`لا يوجد شيء تقليدي في تصميم سيارة ${modelName} الجديدة. لم تكن أبدًا من الأشخاص الذين لا يُلفتون الأنظار، والآن أصبحت سيارتك ${modelName} الجديدة امتدادًا لذلك. المنصة تأتي إليك لاختيار السّيارة التي ستكون بمثابة ملحق مثالي لأسلوبك الشّخصي المثير للإعجاب بالفعل. لأية أسئلة أخرى متعلّقة بالتّصميم، لا تتردد في الضّغط على الزّر أدناه.`,
						en:`There’s nothing subtle about the design of your new ${modelName}. You’ve never been one to blend in, now your new ${modelName} is an extension of that. Props go out to you for selecting a vehicle that will serve as the perfect accessory to your already impressive personal style.`
					}
				},
				cockpit:{
					title:{
						ar:`حضرة الكابتن، تعرّف على قمرة القيادة الخاصة بك`,
						en:`Captain, <br/> meet your cockpit.`
					},
					body:{
						ar:`كان مركز التّحكم في المهمة ينتظر قائدًا من مستواك. هنا نقدم لك لمحة عن الشّرنقة التي سوف تتصل من خلالها بالمنزل، بعيدًا عن المنزل. ألقِ نظرة على المكان الذي أصبحت فيه أنت وسيارتك ${modelName} كيانًا واحدًا: قمرة القيادة. من خلال النّقر على الزّر أدناه، يكون فريقنا جاهزًا لمنحك مزيدًا من المعلومات حول المولود الملكي الجديد.`,
						en:`Mission control has been waiting for a captain like you. Here we give you a glimpse into the cocoon you will call home away from home. Take a glimpse into where you and your new ${modelName} become one entity: the cockpit. By tapping the button below our team is ready to give you more information on your new royal perch.`
					}
				},
				tomorrow:{
					title:{
						ar:`تولي مسؤولية الغد`,
						en:`Take charge <br/> of tomorrow.`
					},
					body:{
						ar:`تهاني الحارّة جاهزة لتقدم لك وتترقب معرفة إلى أين يتجه مستقبل القيادة. من خلال الطّلب المسبق لسيارة ${modelName}، تُصبح من الأوائل في المنطقة الذين يشكلون جزءًا من الطّريق السّريع الكهربائي بالكامل نحو المستقبل. تعرف أكثر على الأمور التي تجعلك تتقدّم على زملائك من خلال النّقر على الزّر أدناه.`,
						en:`A huge congratulations are in order for you anticipating where the future of driving is headed. By pre-ordering your ${modelName}, you become one of the first in the region to form part of the future’s fully electric highway. Learn more about what puts you ahead of your peers by tapping on the button below.`
					}
				},
				sound:{
					title:{
						ar:`سر صوتنا الصّغير`,
						en:`Our little sound secret.`
					},
					body:{
						ar:`بصفتك متحمسًا للسّيارات، يُحركك الصّوت. لهذا السّبب نسمح لك بالإطّلاع على سرنا المتعلّق بالموسيقى التّصويرية الفريدة لسيارة ${modelName} الجديدة جنبًا إلى جنب مع أجزاء مثيرة أخرى من التّقنيات الجديدة لإشباع رغباتك بالحصول على تقنيات المستقبل. اضغط على الزّر أدناه للحصول على إجابات عن مختلف الأسئلة الملحّة.`,
						en:`As automotive enthusiast, sound moves you. That’s why we want to let you in on our secret to the unique soundtrack of your new ${modelName} along with other thrilling bits of new technology to satisfy your futuristic cravings.`
					}
				},
				tech:{
					title:{
						ar:`حلم الشّركات النّاشئة في مجال التّقنية`,
						en:`The tech <br/> start-ups dream of.`
					},
					body:{
						ar:`قم بإشباع رغباتك بالحصول على تقنيات المستقبل، بإلقاء نظرة خاطفة على ما يجعل سيارة ${modelName} الجديدة تبرز إلى جانب قدراتها على توفير تجربة قيادة رائدة. من أنظمة الصّوت إلى أنظمة المساعدة، كن مطمئنًا إلى أنّ التّقنية التي طلبتها مسبقًا قائمة على أحدث المعايير الجديدة. انقر فوق الزّر أدناه للدردشة مع أحد وكلائنا المطلعين بكل ما يتعلق بالتّقنيات في سيارة ${modelName} المستقبلية.`,
						en:`Satisfy your futuristic craving with a peek into what makes your new ${modelName} tick outside of it’s captivating driving capabilities. From sound to assistance systems, rest assured the technology you’ve pre-ordered is the new state-of-the-art standard. Tap the button below to chat to one of our knowledgeable agents about all things tech in your future ${modelName}.`
					}
				},
				outro:{
					title:{
						ar:'الخطوات التالية',
						en:'Next steps.'
					},
					body:{
						ar:`لا تتردد في تنزيل كُتيّب ${modelName} أدناه.`,
						en:`Feel free to download the ${modelName} brochure.`
					}
				}
			}
		}
	}

	capitalize = (s) => {
  		if(typeof s !== 'string') return ''
  		return s.charAt(0).toUpperCase() + s.slice(1)
	}

	render(){
		const { form, proceed, busy, sent, locale } = this.state;
		const { mobi, model, modelSlug, regionSlug, name, region, arabic } = this.props;
		const urlParams = new URLSearchParams(window.location.search);
		const entries = urlParams.entries();
		let modelName = model === 'rs-e-tron-gt' ? 'RS e-tron GT':'e-tron GT';

		return (
			<div className={`thanks container`} dir={arabic ? 'rtl':'ltr'} lang={arabic ? 'ar':'en'}>
				<div className="content">
					<div className={`video-intro`}>
						<div className="video-holder">
							<video width="1920" height="720" src={`${process.env.PUBLIC_URL}/assets/video/hero${mobi ? '-mobi':''}.mp4`} poster={`${process.env.PUBLIC_URL}/assets/img/poster/hero${mobi ? '-mobi':''}-poster.jpg`} ref={this.introvideo} playsInline autoPlay loop muted/>
							<div className="center-play">
								{!mobi && <Intro text={locale.title[arabic ? 'ar':'en']} body={locale.subtitle[arabic ? 'ar':'en']} buttons={[{text:locale.ctaTour[arabic ? 'ar':'en'],href:'tel:+96262221693',type:'primary'},{text:locale.ctaBrochure[arabic ? 'ar':'en'],href:`${process.env.PUBLIC_URL}/assets/brochure/${modelSlug}/${region}/brochure.pdf`,type:'primary'}]}/>}
							</div>
						</div>
					</div>
					{mobi && <Intro text={locale.title[arabic ? 'ar':'en']} body={locale.subtitle[arabic ? 'ar':'en']} buttons={[{text:locale.ctaTour[arabic ? 'ar':'en'],href:'tel:+9711111111',type:'primary'},{text:locale.ctaBrochure[arabic ? 'ar':'en'],href:`${process.env.PUBLIC_URL}/assets/brochure/${modelSlug}/${region}/brochure.pdf`,type:'primary'}]}/>}
					<Footer modelSlug={modelSlug} regionSlug={regionSlug}/>
				</div>
			</div>
		);
	}
}
// connects redux store to component
function mapStateToProps(state){
  	return {
  		form:state.form,
  		mobi:state.mobi,
  		verify:state.verify
  	}
}

// export with redux connect
export default withRouter(connect(mapStateToProps)(Complete));
