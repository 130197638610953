import React, { Component,Fragment } from 'react';

// plugins
import parse from 'html-react-parser';
import handleViewport from 'react-in-viewport';

class getWords extends Component {

	constructor(){
		super();
		this.state = {
			status:'down'
		}
		this.reveal = React.createRef();
	}
	componentDidMount(){
		// window.addEventListener('scroll', this.scrolling);
		// this.scrolling();
	}
	// scrolling = (e) => {
	// 	if(this.reveal.current){
	// 		let top = window.pageYOffset || (document.documentElement.clientHeight ? document.documentElement.scrollTop : document.body.scrollTop);
	// 		let offset = this.reveal.current.offsetTop,
	// 			height = this.reveal.current.clientHeight;
	// 		if((top + window.innerHeight - height) >= offset){
	// 			this.setState({status:'in'});
	// 		}
	// 	}
	// }
	render(){
		const { status } = this.state;
		const { className, text, delay, enterCount } = this.props;
		// timing
		let speed = 1,
			between = 0.01;
		//end timing
		let words;
		words = text.split(' ');		

		let element = words.map((item,k) => {
			let word = parse(item);
			return (
				typeof word === 'string' ? 
					<Fragment key={k}>
						<span 
							className={`ch`} 
							style={{transitionDelay:`${delay + (between*k)}s`,transitionDuration:`${speed}s`}}
							>
							{parse(word)}
							</span>
							<span> </span>
					</Fragment>
				: <Fragment key={k}>{word}</Fragment>
			);
		})
		// render
		return ( 
			<span className={`words ${className ? className:''} ${enterCount > 0 ? 'in':'down'}`} ref={this.reveal}>
				{element}
			</span>
		)
	}
}
const WordsInstant = handleViewport(getWords, { rootMargin: '0px' });
const Words = handleViewport(getWords, { rootMargin: '-100px' });

export {
  WordsInstant,
  Words,
}