import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

import configuration from './config.json';

// redux
import { connect } from 'react-redux';
import { setVerify } from './redux/actions';
// ui 
import { 	
	ThemeProvider,
	audiDarkTheme,
	audiLightTheme,
	Layout, 
	LayoutItem, 
	Text, 
	FormGroup, 
	TextField, 
	Select, 
	Checkbox, 
	Button 
} from '@audi/audi-ui-react';


// modules
import Intro from './modules/Intro';
import Footer from './modules/Footer';

// plugins
import axios from 'axios';

import Cards from 'react-credit-cards';

const ProgressIcon = (props) => {
	return (
		<div className={`progress-loader ${props.state ? props.state : ''}`}>
			<svg viewBox="0 0 80 80">
				<circle className="circle-start"  cx="40" cy="40" r="36" fill="transparent" strokeWidth="4" stroke="rgba(255,255,255,.5)"/>
			    <circle className="circle" cx="40" cy="40" r="36" fill="transparent" stroke="white" strokeWidth="4"/>
			    <circle className="circle-end" cx="40" cy="40" r="36" fill="transparent" stroke="white" strokeWidth="4"/>
		    </svg>
		    <div className="check-icon"></div>
		</div>
    );
}

class Checkout extends Component {
	static propTypes = {
	    location: PropTypes.object.isRequired,
	    history: PropTypes.object.isRequired
	}

	constructor(props){
		super(props);

		let arabic = this.props.arabic,
			region = this.props.region;

		let currentYear = new Date().getFullYear().toString().substr(-2);
		let years = [];
		for(let i = 0;i < 10;i++){
			years.push(parseInt(currentYear)+i);
		}

		let modelName = arabic ? this.props.modelNameAR : this.props.modelName;

		this.state = {
			color:region === 'du' ? 'red' : region === 'az' ? 'blue':'gold',
			focus:'',
			pass:"9345p7p1Xmzt0CTvdYYnVr)-",
			form: {
				card_security_code: '',
			    card_holder_name: '',
			    card_number: '',
			    service_command:'TOKENIZATION',
				access_code:process.env.REACT_APP_PAYFORT_ACCESS_CODE,
				merchant_identifier:process.env.REACT_APP_MERCHANT_IDENTIFIER,
				merchant_reference:this.getrandom(),
				language:arabic ? 'ar':'en',
				merchant_extra1:'500000',//500000
				merchant_extra2:this.props.emailRef ? this.props.emailRef:'',
				merchant_extra3:this.props.modelSlug,
				return_url:process.env.REACT_APP_RETURN_URL
			},
			expiry_month: '',
			expiry_year: '',
			years:years,
			signature:false,
			busy:false,
			formsent:false,
			formError:true,
			monthError:true,
			yearError:true,
			emailError:false,
			status:false,
			email:'',
			emailready:false,
			emailbusy:false,
			emailsent:false,
			emailerror:false,
			emailalready:false,
			config:false,
			checkoutErrorMessage:{
				ar:'',
				en:''
			},
			locale:{
				complete:{
					ar:"اكمل طلبك.",
					en:"Complete your order."
				},
				description:{
					ar:`لتأمين حجز سيارة ${modelName} ، يرجى إكمال المعاملة أدناه. <br/> تم تحديد مبلغ الطّلب المبدئي بـ 5،000 درهم إماراتي ويضمن توفّر المخزون. <br/> سيتم توجيهك إلى بوابة المعاملات الآمنة الخاصة بنا لإكمال الطّلب. يقدر سعر ${modelName} بـ ${modelName.indexOf('RS') < 0 ? '420,000':'555,000'} درهم إماراتي. `,
					en:`To secure your ${modelName}, please complete the transaction below. <br/> The pre-order amount is set at AED 5,000 and guarantees stock availability. <br/> You will be directed to our secure transactional portal to complete the request. <br/> The ${modelName} is valued at AED ${modelName.indexOf('RS') < 0 ? '420,000':'555,000'}.`
				},
				paybar:{
					ar:`${this.props.modelName}`,
					en:`${modelName}`
				},
				email:{
					text:{
						ar:'البريد الإلكتروني',
						en:'Email address'
					},
					error:{
						ar:'يرجى ادخال عنوان البريد الإلكتروني الصحيح.',
						en:'Please enter a correct Email address'
					}
				},
				number:{
					text:{
						ar:'رقم البطاقة',
						en:'Card number'
					}
				},
				name:{
					text:{
						ar:'الاسم على البطاقة',
						en:'Name on card'
					}
				},
				date:{
					text:{
						ar:'تاريخ انتهاء الصّلاحية',
						en:'Expiry date'
					}
				},
				month:{
					text:{
						ar:'MM',
						en:'MM'
					}
				},
				year:{
					text:{
						ar:'YY',
						en:'YY'
					}
				},
				code:{
					text:{
						ar:'CVV / CVC',
						en:'CVV / CVC'
					}
				},
				emailsubmit:{
					text:{
						ar:'استمر',
						en:'Continue'
					},
					processing:{
						ar:'أرجو الإنتظار',
						en:'Processing'
					},
					sent:{
						ar:'أرجو الإنتظار',
						en:'Loading Checkout'
					},
					error:{
						ar:'لم يتم العثور على تطابق',
						en:'No match'
					},
					already:{
						ar:'مدفوع بالفعل',
						en:'Already paid'
					}
				},
				submit:{
					text:{
						ar:'الدّفع',
						en:'Checkout'
					},
					processing:{
						ar:'أرجو الإنتظار',
						en:'Processing'
					}
				},
				fine:{
					ar:<span>يمكن استخدام جميع البيانات الشّخصية المقدمة من خلال هذا النّموذج والاحتفاظ بها بواسطة Audi AG و Audi Volkswagen Middle East FZE ووكلائها المعتمدين ومقدمي خدماتها لأغراضٍ ترويجية أو تسويقية. لا ينطبق إلغاء الاشتراك من النّموذج أعلاه على الأنواع الأخرى من الاتصالات التّسويقية التّرويجية. الاتصال: <a href="mailto:rsvp@audi.avme.ae" style={{display:'inline-block'}}>rsvp@audi.avme.ae</a></span>,
					en:<span>All personal data provided through this form may be used and retained by Audi AG, Audi Volkswagen Middle East FZE, its authorized dealers and its service providers for promotional or marketing purposes. Unsubscribing from the above form does not apply to other types of promotional marketing communication. <b>Contact: <a href="mailto:rsvp@audi.avme.ae" style={{display:'inline-block'}}>rsvp@audi.avme.ae</a></b></span>
				}
			}
		}

		this.monthRef = React.createRef();
		this.yearRef = React.createRef();

	}

	componentDidMount(props){

		// keypress
		// document.querySelector('form.email-checker').onsubmit
		document.addEventListener('keyup',(e) => {
			if(e.keyCode === 13){
				e.preventDefault();
				e.stopPropagation();
				this.emailSubmit()
			}
		})
		// access
		let fromform = this.props.checkout;
		// no ref from direct!!
		if(fromform){
			// came from form
			// add token for security? think about it

			// gen sig coz email set
			this.generateSignature();

			const { history,arabic,modelSlug } = this.props;

			axios({
		        method: 'post',
		        responseType: 'json',
		        url: 'https://purchase.audimiddleeast.com/auth/status.php',
		        data: {
		        	email:this.props.emailRef
		        },
			  	headers: {
			  		'Authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT'
			  	}
		    }).then((res)=>{
		    	let status = res.data.message,
		    	config = res.data.config !== '' ? JSON.parse(res.data.config):false;
		    	if(status === 'paid'){
		    		this.props.setVerify(true);
		    		history.push('/complete');
		    	}else if(status === 'unpaid'){
		    		this.setState({status:status})
		    		if(config){
		    			this.setState({config:config})
		    		}
		    	}else if(status === 'notfound'){
		    		// error and form reload
		    		if(arabic){
		    			history.push('/'+modelSlug+'/ar');
		    		}else {
		    			history.push('/'+modelSlug);
		    		}
		    	}else {
		    		// error and form reload
		    		if(arabic){
		    			history.push('/'+modelSlug+'/ar');
		    		}else {
		    			history.push('/'+modelSlug);
		    		}
		    	}
		    })
		}else {
			this.setState({status:'email'})
		}

		



		// other

		if(this.monthRef.current){
			this.monthRef.current.querySelector('input').placeholder = 'MM';
		}
		if(this.yearRef.current){
			this.yearRef.current.querySelector('input').placeholder = 'YY';
		}
	}

	handleInputFocus = (e) => {
    	this.setState({ focus: e.target.id });
  	}
  
 	handleInputChange = (e) => {
 		// error must stay on month
 		const { form,years,expiry_month,expiry_year,checkoutErrorMessage,formError,monthError,yearError } = this.state;
    	const { id, name, value } = e.target;
    	if(id === 'expiry_month'){
    		if(parseInt(value) < 1 || parseInt(value) > 12){
    			let message = checkoutErrorMessage;
	    			message.ar = 'Please enter a valid expiry month';
	    			message.en = 'Please enter a valid expiry month';
    			this.setState({checkoutErrorMessage:message});
    		}else {
    			let message = checkoutErrorMessage;
	    			message.ar = '';
	    			message.en = '';
	    		this.setState({checkoutErrorMessage:message})
    		}
    		if(value === '' || value.length !== 2 || parseInt(value) < 1 || parseInt(value) > 12){
    			this.setState({monthError:true})
    		}else {
				this.setState({monthError:false})
    		}
    		this.setState({expiry_month:value})
    	}else if(id === 'expiry_year'){
    		if(value.length === 2 && !years.includes(parseInt(value))){
    			let message = checkoutErrorMessage;
	    			message.ar = 'Please enter a valid expiry year';
	    			message.en = 'Please enter a valid expiry year';
    			this.setState({checkoutErrorMessage:message})
    		}else {
    			let message = checkoutErrorMessage;
	    			message.ar = '';
	    			message.en = '';
	    		this.setState({checkoutErrorMessage:message})
    		}
    		if(value === '' || value.length !== 2 || !years.includes(parseInt(value))){
    			this.setState({yearError:true})
    		}else {
				this.setState({yearError:false})
    		}
    		this.setState({expiry_year:value})
    	}else {
    		let form = this.state.form;
		    	form[name] = value;
		    	this.setState({ form: form });
		    	// errors
			    if(form.card_number.length !== 16){
		    		this.setState({formError:true})
		    	}else if(form.card_holder_name === ''){
		    		this.setState({formError:true})
		    	}else if(form.card_security_code.length !== 3 && form.card_security_code.length !== 4){
		    		this.setState({formError:true})
		    	}else {
		    		this.setState({formError:false})
		    	}
    	}

  	}

  	handleEmail = (e) => {
  		const { name, value } = e.target;
  		let reg = /^(?!.*\.{2})([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  		if(value === ''){
  			this.setState({emailError:true,emailready:false})
  		}else {
  			if(!reg.test(value)){
				this.setState({emailError:true,emailready:false})
			}else {
				this.setState({emailError:false,emailready:true})
			}
  		}
  		this.setState({email:value})
  	}

  	emailSubmit = (e) => {

  		const { history,arabic,modelSlug } = this.props;

  		this.setState({emailbusy:true});
  		axios({
	        method: 'post',
	        responseType: 'json',
	        url: 'https://purchase.audimiddleeast.com/auth/status.php',
	        data: {
	        	email:this.state.email
	        },
		  	headers: {
		  		'Authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT'
		  	}
	    }).then((res)=>{
	    	let status = res.data.message,
	    	config = res.data.config !== '' ? JSON.parse(res.data.config):false;
	    	if(status === 'paid'){
	    		this.setState({emailbusy:false,emailalready:true})
	    		this.props.setVerify(true);
	    		history.push('/complete');
	    	}else if(status === 'unpaid'){
	    		this.setState({emailbusy:false,emailsent:true,status:status})
	    		if(config){
	    			this.setState({config:config})
	    		}
	    		this.generateSignature();
	    	}else if(status === 'notfound'){
	    		// error and form reload
	    		this.setState({emailbusy:false,emailerror:true})
				if(arabic){
	    			history.push('/'+modelSlug+'/ar');
	    		}else {
	    			history.push('/'+modelSlug);
	    		}
	    	}else {
	    		// error and form reload
	    		this.setState({emailbusy:false,emailerror:true})
	    		if(arabic){
	    			history.push('/'+modelSlug+'/ar');
	    		}else {
	    			history.push('/'+modelSlug);
	    		}
	    	}
	    })
  	}

  	checkoutSubmit = (e) => {
  		this.setState({formsent:true})
  	}

  	getrandom = () => {
		return Math.random() * (2147483647 - 0) + 0;
	}

	generateSignature = () => {

		function sha256(ascii){
		    function rightRotate(value, amount) {
		        return (value>>>amount) | (value<<(32 - amount));
		    };
		    
		    var mathPow = Math.pow;
		    var maxWord = mathPow(2, 32);
		    var lengthProperty = 'length'
		    var i, j; // Used as a counter across the whole file
		    var result = ''

		    var words = [];
		    var asciiBitLength = ascii[lengthProperty]*8;
		    
		    //* caching results is optional - remove/add slash from front of this line to toggle
		    // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
		    // (we actually calculate the first 64, but extra values are just ignored)
		    var hash = sha256.h = sha256.h || [];
		    // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
		    var k = sha256.k = sha256.k || [];
		    var primeCounter = k[lengthProperty];
		    /*/
		    var hash = [], k = [];
		    var primeCounter = 0;
		    //*/

		    var isComposite = {};
		    for (var candidate = 2; primeCounter < 64; candidate++) {
		        if (!isComposite[candidate]) {
		            for (i = 0; i < 313; i += candidate) {
		                isComposite[i] = candidate;
		            }
		            hash[primeCounter] = (mathPow(candidate, .5)*maxWord)|0;
		            k[primeCounter++] = (mathPow(candidate, 1/3)*maxWord)|0;
		        }
		    }
		    
		    ascii += '\x80' // Append Ƈ' bit (plus zero padding)
		    while (ascii[lengthProperty]%64 - 56) ascii += '\x00' // More zero padding
		    for (i = 0; i < ascii[lengthProperty]; i++) {
		        j = ascii.charCodeAt(i);
		        if (j>>8) return; // ASCII check: only accept characters in range 0-255
		        words[i>>2] |= j << ((3 - i)%4)*8;
		    }
		    words[words[lengthProperty]] = ((asciiBitLength/maxWord)|0);
		    words[words[lengthProperty]] = (asciiBitLength)
		    
		    // process each chunk
		    for (j = 0; j < words[lengthProperty];) {
		        var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
		        var oldHash = hash;
		        // This is now the undefinedworking hash", often labelled as variables a...g
		        // (we have to truncate as well, otherwise extra entries at the end accumulate
		        hash = hash.slice(0, 8);
		        
		        for (i = 0; i < 64; i++) {
		            var i2 = i + j;
		            // Expand the message into 64 words
		            // Used below if 
		            var w15 = w[i - 15], w2 = w[i - 2];

		            // Iterate
		            var a = hash[0], e = hash[4];
		            var temp1 = hash[7]
		                + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
		                + ((e&hash[5])^((~e)&hash[6])) // ch
		                + k[i]
		                // Expand the message schedule if needed
		                + (w[i] = (i < 16) ? w[i] : (
		                        w[i - 16]
		                        + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15>>>3)) // s0
		                        + w[i - 7]
		                        + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2>>>10)) // s1
		                    )|0
		                );
		            // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
		            var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
		                + ((a&hash[1])^(a&hash[2])^(hash[1]&hash[2])); // maj
		            
		            hash = [(temp1 + temp2)|0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
		            hash[4] = (hash[4] + temp1)|0;
		        }
		        
		        for (i = 0; i < 8; i++) {
		            hash[i] = (hash[i] + oldHash[i])|0;
		        }
		    }
		    
		    for (i = 0; i < 8; i++) {
		        for (j = 3; j + 1; j--) {
		            var b = (hash[i]>>(j*8))&255;
		            result += ((b < 16) ? 0 : '') + b.toString(16);
		        }
		    }
		    return result;
		}

		let {pass,form} = this.state;
		let string = '';
		let data = {
			"service_command":"TOKENIZATION",
			"access_code":form.access_code,
			"merchant_identifier":form.merchant_identifier,
			"merchant_reference":form.merchant_reference,
			"language":form.language,
			"merchant_extra1":form.merchant_extra1,
			"merchant_extra2":this.props.emailRef ? this.props.emailRef:this.state.email,
			"merchant_extra3":this.props.modelSlug,
			"return_url":form.return_url
		}

		Object.entries(data).sort().forEach((el) => {
			string += `${el[0]}=${el[1]}`;
		})

		let toHash = pass+string+pass;
		let signature = sha256(toHash);
		this.setState({signature:signature})
	}

	render(){
		const { form, focus, expiry_month, expiry_year, years, signature, busy, locale, checkoutErrorMessage ,formError, monthError, yearError, emailError, emailready, emailbusy, emailsent, emailalready, emailerror, status, formsent, color, config } = this.state;
		const { arabic, mobi, model, region, modelSlug, regionSlug } = this.props;
		let modelName = arabic ? this.props.modelNameAR : this.props.modelName;

		let configimg = !config ? '6Y6Y-44I':`${config.color}-${config.wheel}`;
		return (
			status !== 'paid' &&
			status === 'unpaid' ?
			<>
				<div className="checkout-container container" dir={arabic ? 'rtl':'ltr'} lang={arabic ? 'ar':'en'}>
					<ThemeProvider theme={audiDarkTheme}>
						<div className="checkout-summary">
							<img src={`${process.env.PUBLIC_URL}/assets/img/stock/${modelSlug}/${configimg}.png`} alt="model thumb"/>
							<Intro 
								text={locale.complete[arabic ? 'ar':'en']} 
								body={locale.description[arabic ? 'ar':'en']}/>
						</div>	
					</ThemeProvider>
					<ThemeProvider theme={audiLightTheme}>
						<Layout direction="row" justify="between" align="center" className="summary-header">
							<Text as="h2" variant="order3" weight="bold">{locale.paybar[arabic ? 'ar':'en']}</Text>
							<Text as="h2" variant="order3" weight="bold">AED 5,000</Text>	
						</Layout>
						<Layout className="payment-content" justify="center" direction="column">
							<Layout className="summary-content" justify="between" direction="column">
								<Layout direction={mobi ? 'column':'row'} justify="between">
								{
									config &&
									<LayoutItem basis="auto" grow="0" align="start">
										{
											arabic ? 
											<>
											<Text as="p" variant="copy1" weight="bold" spaceStackEnd="m">تفاصيل السيارة</Text>
											<Text as="p" variant="copy1"><b>الخارجية: </b> 
											{configuration[arabic ? 'ar':'en'].colors[config.color]}.
											</Text>
											<Text as="p" variant="copy1"><b>العجلات: </b> 
											{configuration[arabic ? 'ar':'en'].wheels[config.wheel]}.
											</Text>
											<Text as="p" variant="copy1"><b>الداخلية: </b> 
											{configuration[arabic ? 'ar':'en'].interior[config.interior]}.
											</Text>
											</>
											:
											<>
											<Text as="p" variant="copy1" weight="bold" spaceStackEnd="m">Vehicle details</Text>
											<Text as="p" variant="copy1"><b>Exterior: </b> 
											{configuration[arabic ? 'ar':'en'].colors[config.color]}.
											</Text>
											<Text as="p" variant="copy1"><b>Wheels: </b> 
											{configuration[arabic ? 'ar':'en'].wheels[config.wheel]}.
											</Text>
											<Text as="p" variant="copy1"><b>Interior: </b> 
											{configuration[arabic ? 'ar':'en'].interior[config.interior]}.
											</Text>
											</>
										}
									</LayoutItem>
								}
									<LayoutItem basis="auto" grow="0" align="start">
										<Text as="p" variant="copy1" weight="bold" spaceStackEnd="m">{arabic ? 'تفاصيل الشّراء':'Purchase details'}</Text>
										<Text as="p" variant="copy1">
										{
											arabic ? 
											<><b>وقت الوصول المتوقع:</b> Q3 2021</>
											:
											<><b>Availability:</b> Q3 2021</>
										}
										</Text>
										<Text as="p" variant="copy1">
										{
											arabic ? 
											<><b>إسترداد الأموال متاح:</b> نعم</>
											:
											<><b>Refund available:</b> Yes</>
										}
										</Text>
									</LayoutItem>
									<Text as="p" variant="copy1"></Text>
								</Layout>
							</Layout>
							<Layout className="card-content" justify="center">
								<LayoutItem grow="0" shrink="0" basis={'50%'} align="center" className="card-holder" spaceInlineEnd="m">
									<Layout justify="end">
										<LayoutItem>
											<Cards
									          cvc={form.card_security_code}
									          expiry={expiry_month+'/'+expiry_year}
									          focused={focus}
									          name={form.card_holder_name}
									          number={form.card_number}
									        />
									    </LayoutItem>
							        </Layout>
						        </LayoutItem>
						        <LayoutItem grow="0" shrink="0" basis={'50%'} spaceInlineStart="m" className="card-form">
							        <form action="https://checkout.payfort.com/FortAPI/paymentPage" method="post" className="checkout-form" onSubmit={this.checkoutSubmit}>
										<LayoutItem className="form-group" spaceStackEnd="m">
								        	<TextField
											    inputId="card_number"
											    name="card_number"
											    type="tel"
											    label={locale.number.text[arabic ? 'ar':'en']}
											    value={form.card_number}
											    invalid={false}
											    required
											    maxLength={16}
											    minLength={16}
											    pattern="[0-9.]*"
											    onFocus={this.handleInputFocus}
											    onChange={(e)=>{
											    	let val = e.currentTarget.value;
											    	if(!isNaN(val)){
											    		this.handleInputChange(e)
											    	}
											    }}
												/>
										</LayoutItem>
										<LayoutItem className="form-group" spaceStackEnd="m">
							        		<TextField
											    inputId="card_holder_name"
											    name="card_holder_name"
											    type="text"
											    label={locale.name.text[arabic ? 'ar':'en']}
											    value={form.card_holder_name}
											    invalid={false}
											    required
											    onChange={this.handleInputChange}
											    onFocus={this.handleInputFocus}
												/>
										</LayoutItem>
										<Layout justify="between" spaceStackStart="xl" direction={mobi ? 'column':'row'}>
											<LayoutItem grow="0" shrink="1" basis={'auto'} align={mobi ? 'start':'center'} className="form-group" spaceStackEnd="m" spaceInlineEnd="l">
										        <FormGroup label={locale.date.text[arabic ? 'ar':'en']} required={true}>
										        	<Layout align="center">
										        		<LayoutItem basis="auto">
													        <TextField
															    inputId="expiry_month"
															    type="tel"
															    label=""
															    value={expiry_month}
															    invalid={false}
															    required
															    spaceInlineEnd="xs"
															    maxLength={2}
															    minLength={2}
															    pattern="[0-9.]*"
															    ref={this.monthRef}
															    onFocus={this.handleInputFocus}
															    onChange={(e)=>{
															    	let val = e.currentTarget.value;
															    	if(!isNaN(val)){
															    		this.handleInputChange(e)
															    	}
															    }}
																/>
														</LayoutItem>
														<LayoutItem basis="auto">
															<Text as="p" variant="copy1" className="date-divider">/</Text>
														</LayoutItem>
														<LayoutItem basis="auto">
															<TextField
															    inputId="expiry_year"
															    type="tel"
															    label=""
															    value={expiry_year}
															    invalid={false}
															    required
															    spaceInlineStart="xs"
															    maxLength={2}
															    minLength={2}
															    pattern="[0-9.]*"
															    ref={this.yearRef}
															    onFocus={this.handleInputFocus}
															    onChange={(e)=>{
															    	let val = e.currentTarget.value;
															    	if(!isNaN(val)){
															    		this.handleInputChange(e)
															    	}
															    }}
																/>
														</LayoutItem>
													</Layout>
											  	</FormGroup>
											</LayoutItem>
											<LayoutItem grow="0" shrink="1" basis={'auto'} align={mobi ? 'start':'center'} className="form-group" spaceStackEnd="m" spaceInlineEnd="l">
										        <TextField
												    inputId="cvc"
												    name="card_security_code"
												    type="text"
												    label={locale.code.text[arabic ? 'ar':'en']}
												    value={form.card_security_code}
												    invalid={false}
												    maxLength={4}
												    minLength={3}
												    pattern="[0-9.]*"
												    required
												    onFocus={this.handleInputFocus}
												    onChange={(e)=>{
												    	let val = e.currentTarget.value;
												    	if(!isNaN(val)){
												    		this.handleInputChange(e)
												    	}
												    }}
													/>
											</LayoutItem>
											<LayoutItem grow="0" shrink="1" basis={'auto'} align={mobi ? 'start':'center'} className="form-group" spaceStackEnd="m">
												<input type="hidden" name="service_command" value="TOKENIZATION" readOnly/>
										        <input type="hidden" name="expiry_date" value={expiry_year+expiry_month} readOnly/>
												<input type="hidden" name="access_code" value={form.access_code} readOnly/>
												<input type="hidden" name="merchant_identifier" value={form.merchant_identifier} readOnly/>
												<input type="hidden" name="merchant_reference" value={form.merchant_reference} readOnly/>
												<input type="hidden" name="language" value={form.language} readOnly/>
												<input type="hidden" name="merchant_extra1" value={form.merchant_extra1} readOnly/>
												<input type="hidden" name="merchant_extra2" value={this.props.emailRef ? this.props.emailRef:this.state.email} readOnly/>
												<input type="hidden" name="merchant_extra3" value={modelSlug} readOnly/>
												<input type="hidden" name="return_url" value={form.return_url} readOnly/>
												<input type="hidden" name="signature" value={signature} readOnly/>
												<Button 
													type="submit"
													variant="primary" 
													disabled={formError || monthError || yearError || busy || formsent ? true:false}
													id="checkout-btn"
													className={busy ? 'processing':''}
													icon={!busy ? <ProgressIcon state="mail"/>:<ProgressIcon state="process"/>}
													>
											      	{
												      	busy ? locale.submit.processing[arabic ? 'ar':'en']
												      	: locale.submit.text[arabic ? 'ar':'en']
											      	}
											    </Button>
											</LayoutItem>
										</Layout>
										{
									    	checkoutErrorMessage.ar !== '' && checkoutErrorMessage.en !== '' &&
									    	<LayoutItem className="global-error">
									        	<Text as="p" variant="copy2" className="error">{checkoutErrorMessage[arabic ? 'ar':'en']}</Text>
									        </LayoutItem>
									    }
							        </form>
							    </LayoutItem>
						    </Layout>
						    <Layout className="checkout-fine" justify="center" spaceStackStart="xxl">
						    	<Text as="p" variant="copy2">
						    		{locale.fine[arabic ? 'ar':'en']}<br/><br/>
						    		{
						    			!config && arabic &&
						    			<>
						    			•    لا تمثل الألوان الخارجية المعروضة على هذا الموقع ما يتوفّر في المخزون على أرض الواقع، وهي لأغراض التّصميم فقط<br/>
						    			</>
						    		}
						    		{
						    			!config && !arabic &&
						    			<>
						    			•    Exterior colors displayed on this site are not representative of any stock order and are purely for design purposes only.<br/>
						    			</>
						    		}
						    		{
						    			arabic ?
										<>
											• جميع تفاصيل بطاقات الائتمان / الخصم ومعلومات التّعريف الشّخصية لن يتم تخزينها أو بيعها أو مشاركتها أو تأجيرها لأي طرف ثالث
											<br/>
											• يخضع أي نزاع أو مطالبة تنشأ عن أو فيما يتعلق بهذا الموقع الإلكتروني ويتم تفسيره وفقًا لقوانين دولة الإمارات العربية المتحدة
											<br/>
											• الإمارات العربية المتحدة هي مكان واجدنا.
											<br/>
											• لن يتعامل https://purchase.audimiddleeast.com/ أو يقدم أي خدمات أو منتجات إلى أي من الدّول التي تفرض عقوبات على مكتب مراقبة الأصول الأجنبية (OFAC) وفقًا لقانون الإمارات العربية المتحدة
											<br/>
											• يُحظر على القاصرين الذين تقل أعمارهم عن 18 عامًا التّسجيل كمستخدمين لهذا الموقع ولا يُسمح لهم بالتّعامل مع الموقع أو استخدامه.
											<br/>
											• يجب على حامل البطاقة الاحتفاظ بنسخة من سجلات المعاملات وسياسات وقواعد التّاجر.
											<br/>
											• نقبل المدفوعات عبر الإنترنت باستخدام بطاقة الائتمان / الخصم Visa و MasterCard بإدخال العملة / العملات المقبولة.
											<br/>
											• سيتم استرداد المبالغ فقط من خلال طريقة الدّفع الأصلية.

										</>
										:
										<>
											•    All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.<br/>
											•    Any dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of United Arab Emirates.<br/>
											•   United Arab Emirates is our country of domicile.<br/>
											•   https://purchase.audimiddleeast.com/ will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of United Arab Emirates.<br/>
											•    Minors under the age of 18 shall are prohibited to register as a User of this website and are not allowed to transact or use the website.<br/>
											•    The cardholder must retain a copy of transaction records and Merchant policies and rules.<br/>
											•    We accept payments online using Visa and MasterCard credit/debit card in enter the accepted currency/ies.<br/>
											•    Refunds will be done only through the Original Mode of Payment.
										</>
						    		}
						    		</Text>
						    </Layout>
						</Layout>
				    </ThemeProvider>
				</div>
				<Footer region={region} modelSlug={modelSlug} regionSlug={regionSlug}/>
			</>
			: status === 'email' ?
			<>
				<div className="checkout-container container" dir={arabic ? 'rtl':'ltr'} lang={arabic ? 'ar':'en'}>
					<ThemeProvider theme={audiDarkTheme}>
						<div className="checkout-summary">
							<img src={`${process.env.PUBLIC_URL}/assets/img/stock/${modelSlug}/${configimg}.png`} alt="model thumb"/>
							<Intro 
								text={locale.complete[arabic ? 'ar':'en']} 
								body={locale.description[arabic ? 'ar':'en']}/>
						</div>	
					</ThemeProvider>
					<ThemeProvider theme={audiLightTheme}>
						<Layout direction="row" justify="between" align="center" className="summary-header">
							<LayoutItem align="center">
								<Text as="h2" variant="order4" weight="bold">{locale.paybar[arabic ? 'ar':'en']}</Text>
							</LayoutItem>
							<LayoutItem shrink="0" className="prices">
								<Text as="h2" variant="order4" weight="bold">{arabic ? `سعر المركبة: ${modelName.indexOf('RS') < 0 ? '420,000':'555,000'} درهم`:`Vehicle price: AED ${modelName.indexOf('RS') < 0 ? '420,000':'555,000'}`}</Text>	
								<Text as="h2" variant="order4" weight="bold">{arabic ? 'السعر عند الطلب المسبق: 5,000 درهم':'Pre-order amount: AED 5,000'}</Text>	
							</LayoutItem>
						</Layout>
						<Layout className="payment-content" justify="center" direction="column">
							<Layout className="card-content" justify="center" direction="column">
								<form className="email-checker" action="javascript:void(0);">
									<Text as="p" variant="copy1" weight="bold" spaceStackEnd="l">
									{arabic ? 'الرّجاء إدخال بريدك الإلكتروني لتسجيل طلبك.':'Please enter your email to assign your request.'}</Text>
									<TextField
									    inputId="email"
									    name="email"
									    type="email"
									    label={locale.email.text[arabic ? 'ar':'en']}
									    value={this.state.email}
									    required
									    spaceStackEnd={mobi ? 'm':'xs'}
									    invalid={emailError ? true:false}
									    validationMessage={locale.email.error[arabic ? 'ar':'en']}
									    onChange={this.handleEmail}
										/>
									<Button 
										variant="primary" 
										disabled={emailready && !emailbusy ? false:true}
										id="submit-btn"
										className={emailbusy || emailsent ? 'processing':''}
										icon={!emailbusy && !emailsent ? <ProgressIcon state="mail"/>:!emailsent ? <ProgressIcon state="process"/>:<ProgressIcon state="process done"/>}
										onClick={this.emailSubmit}
										>
										{
									      	emailbusy ? locale.emailsubmit.processing[arabic ? 'ar':'en']
									      	: emailsent ? locale.emailsubmit.sent[arabic ? 'ar':'en']
									      	: emailalready ? locale.emailsubmit.already[arabic ? 'ar':'en']
									      	: emailerror ? locale.emailsubmit.error[arabic ? 'ar':'en']
									      	:locale.emailsubmit.text[arabic ? 'ar':'en']
								      	}
								    </Button>
								</form>
						    </Layout>
						    <Layout className="checkout-fine" justify="center" spaceStackStart="xxl">
						    	<Text as="p" variant="copy2">
						    		{locale.fine[arabic ? 'ar':'en']}<br/><br/>
						    		{
						    			!config && arabic &&
						    			<>
						    			•    لا تمثل الألوان الخارجية المعروضة على هذا الموقع ما يتوفّر في المخزون على أرض الواقع، وهي لأغراض التّصميم فقط<br/>
						    			</>
						    		}
						    		{
						    			!config && !arabic &&
						    			<>
						    			•    Exterior colors displayed on this site are not representative of any stock order and are purely for design purposes only.<br/>
						    			</>
						    		}
						    		{
						    			arabic ?
										<>
											• جميع تفاصيل بطاقات الائتمان / الخصم ومعلومات التّعريف الشّخصية لن يتم تخزينها أو بيعها أو مشاركتها أو تأجيرها لأي طرف ثالث
											<br/>
											• يخضع أي نزاع أو مطالبة تنشأ عن أو فيما يتعلق بهذا الموقع الإلكتروني ويتم تفسيره وفقًا لقوانين دولة الإمارات العربية المتحدة
											<br/>
											• الإمارات العربية المتحدة هي مكان واجدنا.
											<br/>
											• لن يتعامل https://purchase.audimiddleeast.com/ أو يقدم أي خدمات أو منتجات إلى أي من الدّول التي تفرض عقوبات على مكتب مراقبة الأصول الأجنبية (OFAC) وفقًا لقانون الإمارات العربية المتحدة
											<br/>
											• يُحظر على القاصرين الذين تقل أعمارهم عن 18 عامًا التّسجيل كمستخدمين لهذا الموقع ولا يُسمح لهم بالتّعامل مع الموقع أو استخدامه.
											<br/>
											• يجب على حامل البطاقة الاحتفاظ بنسخة من سجلات المعاملات وسياسات وقواعد التّاجر.
											<br/>
											• نقبل المدفوعات عبر الإنترنت باستخدام بطاقة الائتمان / الخصم Visa و MasterCard بإدخال العملة / العملات المقبولة.
											<br/>
											• سيتم استرداد المبالغ فقط من خلال طريقة الدّفع الأصلية.

										</>
										:
										<>
											•    All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.<br/>
											•    Any dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of United Arab Emirates.<br/>
											•   United Arab Emirates is our country of domicile.<br/>
											•   https://purchase.audimiddleeast.com/ will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of United Arab Emirates.<br/>
											•    Minors under the age of 18 shall are prohibited to register as a User of this website and are not allowed to transact or use the website.<br/>
											•    The cardholder must retain a copy of transaction records and Merchant policies and rules.<br/>
											•    We accept payments online using Visa and MasterCard credit/debit card in enter the accepted currency/ies.<br/>
											•    Refunds will be done only through the Original Mode of Payment.
										</>
						    		}
						    		</Text>
						    </Layout>
						</Layout>
				    </ThemeProvider>
				</div>
				<Footer region={region} modelSlug={modelSlug} regionSlug={regionSlug}/>
			</>
			:false
		);
	}
}

// connects redux store to component
function mapStateToProps(state){
  	return {
  		mobi:state.mobi,
  		emailRef:state.emailRef,
  		checkout:state.checkout
  	}
}
// to make redux actions as props
const mapDispatchToProps = {
	setVerify
}

// export with redux connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));

