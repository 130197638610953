// data
import data from '../regions.json';
let filter = Object.entries(data).filter((k)=>!k[1].excluded),
	filterRegions = Object.fromEntries(filter);

// arabic
let arabic = false;
if(window.location.href.indexOf('l=ar') > -1 || window.location.href.indexOf('/ar') > -1){
	arabic = true;
}

// region
let region = 'me',
	baseurl = '';

if(window.location.href.indexOf('r=az') > -1 || window.location.href.indexOf('abudhabi') > -1){
	region = 'az';
	baseurl = arabic ? 'https://ar.audimiddleeast.com/azar/':'https://audi-abudhabi.com/me/web/azen/';
}else if(window.location.href.indexOf('r=bh') > -1 || window.location.href.indexOf('bahrain') > -1){
	region = 'bh';
	baseurl = arabic ? 'https://ar.audimiddleeast.com/bhar/':'https://audi-bahrain.com/me/web/bhen/';
}else if(window.location.href.indexOf('r=du') > -1 || window.location.href.indexOf('dubai') > -1){
	region = 'du';
	baseurl = arabic ? 'https://ar.audimiddleeast.com/duar/':'https://audi-dubai.com/me/web/duen/';
}else if(window.location.href.indexOf('r=kw') > -1 || window.location.href.indexOf('kuwait') > -1){
	region = 'kw';
	baseurl = arabic ? 'https://ar.audimiddleeast.com/kwar/':'https://audi-kuwait.com/me/web/kwen/';
}else if(window.location.href.indexOf('r=sa') > -1 || window.location.href.indexOf('saudiarabia') > -1){
	region = 'sa';
	baseurl = arabic ? 'https://ar.audimiddleeast.com/saar/':'https://audi-saudiarabia.com/me/web/saen/';
}else if(window.location.href.indexOf('r=om') > -1 || window.location.href.indexOf('oman') > -1){
	region = 'om';
	baseurl = arabic ? 'https://ar.audimiddleeast.com/omar/':'https://audi-oman.com/me/web/omen/';
}else if(window.location.href.indexOf('r=qa') > -1 || window.location.href.indexOf('qatar') > -1){
	region = 'qa';
	baseurl = arabic ? 'https://ar.audimiddleeast.com/qaar/':'https://audi-qatar.com/me/web/qaen/';
}else if(window.location.href.indexOf('r=jo') > -1 || window.location.href.indexOf('jordan') > -1){
	region = 'jo';
	baseurl = arabic ? 'https://ar.audimiddleeast.com/joar/':'https://audi-jordan.com/me/web/joen/';
}else if(window.location.href.indexOf('r=lb') > -1 || window.location.href.indexOf('lebanon') > -1){
	region = 'lb';
	baseurl = arabic ? 'https://ar.audimiddleeast.com/lbar/':'https://audi-lebanon.com/me/web/lben/';
}

const initialState = {
	arabic:arabic,
	baseurl:baseurl,
	region:region,
	data:filterRegions,
	mobi:window.innerWidth <= 768 ? true:false,
	form:{
		model:'',
    	title:'',
	    name:'',
	    surname:'',
	    email:'',
	    phone:'',
	    country:'',
	    city:'',
	    purchaseDate:'',
	    contactTime:'',
		language:'',
		tracking:'',
		source:'',
		ip:'',
  		type:'',
  		consent:''
	},
	verify:false,
	checkout:false,
	paid:false,
	emailRef:''
};

export default function reducer(state = initialState, action) {
	switch(action.type){
		case 'MOBI':
		return {
			...state,
			mobi:action.payLoad
		}
		case 'FORM':
		return {
			...state,
			form:action.payLoad
		}
		case 'VERIFY':
		return {
			...state,
			verify:action.payLoad
		}
		case 'CHECKOUT':
		return {
			...state,
			checkout:action.payLoad
		}
		case 'PAID':
		return {
			...state,
			paid:action.payLoad
		}
		case 'EMAIL_REF':
		return {
			...state,
			emailRef:action.payLoad
		}
		default:
		return state;
	}
}